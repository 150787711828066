import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomValidators } from 'custom-validators';
import {
	WaTemplateParamsOTD,
	WaTemplateDTO,
	WaTemplateParamsDTO
} from '@enSend/message/whatsapp-template-wizard/models';
import { TUI_VALIDATION_ERRORS } from 'ui-lib';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { WhatsappParamsDTO } from '@enSend/_shared/classes';
import { WhatsappDcTemplate } from './_state/whatsapp-dc-preview.models';
import { WhatsappPreviewService } from './_state/whatsapp-dc-preview.service';
import { WhatsappDcPreviewQuery } from './_state/whatsapp-dc-preview.query';
import { WhatsappPreviewDataService } from './_state/whatsapp-dc-preview-data.service';
import { WhatsappDcFormService } from './services/whatapp-dc-form.service';

@UntilDestroy()
@Component({
	selector: 'en-whatsapp-dc-preview',
	templateUrl: './whatsapp-dc-preview.component.html',
	styleUrls: ['./whatsapp-dc-preview.component.scss'],
	providers: [
		WhatsappPreviewDataService,
		WhatsappPreviewService,
		WhatsappDcFormService,
		{
			provide: TUI_VALIDATION_ERRORS,
			useValue: {
				required: 'whatsapp_message_wizard.validate_requaired',
				invalidEmailOrPhone: 'whatsapp_message_wizard.validate_invalid',
				notFound: 'whatsapp_message_wizard.validate_not_found'
			}
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsappDcPreviewComponent implements OnInit {
	private readonly CONTACTS_LIMIT = 20;

	readonly loading$ = this.whatsappPreviewService.loading$;
	readonly contactsHistory$ = this.query.contactsHistory$;

	contactControl = new UntypedFormControl('', [
		Validators.required,
		CustomValidators.contact
	]);

	disableGenerating = false;
	isError = false;
	defaultParams: WaTemplateParamsOTD;

	@Input() visible = true;
	@Input() messageId: number;
	@Input() template: WaTemplateDTO;

	@Output() hide = new EventEmitter<void>();

	constructor(
		private fb: UntypedFormBuilder,
		private whatsappPreviewService: WhatsappPreviewService,
		private formService: WhatsappDcFormService,
		private query: WhatsappDcPreviewQuery,
		private cd: ChangeDetectorRef
	) {}

	get form(): UntypedFormGroup {
		return this.formService.form;
	}

	get paramsForm(): UntypedFormGroup {
		return this.form.get('params') as UntypedFormGroup;
	}

	get textVariableArray(): UntypedFormArray {
		return this.paramsForm.get('body_text') as UntypedFormArray;
	}

	get footerVariableArray(): UntypedFormArray {
		return this.paramsForm.get('footer_text') as UntypedFormArray;
	}

	get titleVariableArray(): UntypedFormArray {
		return this.paramsForm.controls.header_text as UntypedFormArray;
	}

	get titleMediaArray(): UntypedFormArray {
		return this.paramsForm.controls.header_handle as UntypedFormArray;
	}

	get buttonVariableArray(): UntypedFormArray {
		return this.paramsForm.controls.url_button_params as UntypedFormArray;
	}

	get buttonArray(): UntypedFormArray {
		return this.form.controls.buttons as UntypedFormArray;
	}

	get titleType(): string {
		return (this.form.controls.title as UntypedFormGroup).controls.format
			.value;
	}

	get templateId(): number {
		return this.form.get('id')?.value;
	}

	ngOnInit(): void {
		if (this.template) {
			this.patchTemplateForm(this.template);
			this.defaultParams = this.paramsForm.value;
		}

		this.disableGenerating = this.query.getCount() >= this.CONTACTS_LIMIT;
	}

	patchTemplateForm(template: WaTemplateDTO): void {
		this.form.patchValue(template);
		this.template.params?.body_text?.forEach(variable =>
			this.textVariableArray.push(new UntypedFormControl(variable))
		);

		this.template.params?.header_text?.forEach(variable =>
			this.titleVariableArray.push(new UntypedFormControl(variable))
		);

		this.template.params?.url_button_params?.forEach(variable =>
			this.buttonVariableArray.push(new UntypedFormControl(variable))
		);

		this.template.params?.header_handle?.forEach(variable =>
			this.titleMediaArray.push(new UntypedFormControl(variable))
		);

		this.template.buttons?.forEach(button =>
			this.buttonArray.push(this.fb.group(button))
		);
	}

	closePreview() {
		this.hide.emit();
	}

	submit() {
		if (this.contactControl.invalid) {
			this.contactControl.markAsDirty();
			return;
		}

		this.isError = false;

		const Data: WhatsappDcTemplate = {
			messageId: this.messageId,
			templateId: this.templateId,
			phone: this.contactControl.value.toString(),
			params: this.getParams()
		};

		this.whatsappPreviewService
			.generatePreview(Data)
			.pipe(
				untilDestroyed(this),
				tap(result => {
					this.disableGenerating =
						this.query.getCount() >= this.CONTACTS_LIMIT - 1;

					const maxContactId = Math.max(
						...this.query.getAll().map(item => Number(item.id)),
						0
					);

					this.whatsappPreviewService.addContact({
						id: maxContactId + 1,
						contact: this.contactControl.value,
						body: result.params
					});
					this.patchDC(result.params);
					this.cd.markForCheck();
				}),
				catchError(e => {
					this.validateErrors(e.error.message);
					this.cd.markForCheck();
					return throwError(e);
				})
			)
			.subscribe();
	}

	selectContact(params: WaTemplateParamsDTO) {
		this.patchDC(params);
	}

	removeContact(id: number) {
		this.whatsappPreviewService.removeContact(id);
		this.disableGenerating = false;
	}

	removeAllContacts() {
		this.whatsappPreviewService.removeAllContacts();
		this.disableGenerating = false;
	}

	private patchDC(params: WaTemplateParamsDTO) {
		if (params.body_text && params.body_text[0].length) {
			const value = params.body_text[0].map(item => {
				return item.length ? item : ' ';
			});
			this.textVariableArray.patchValue(value);
		}
		if (params.footer_text && params.footer_text[0].length)
			this.footerVariableArray.patchValue(params.footer_text[0]);
		if (params.header_text?.length)
			this.titleVariableArray.patchValue(params.header_text);
		if (params.url_button_params?.length)
			this.buttonVariableArray.patchValue(params.url_button_params);

		this.form.updateValueAndValidity();
	}

	private getParams(): WaTemplateParamsDTO {
		return new WhatsappParamsDTO(
			this.defaultParams,
			this.titleType
		).getValue();
	}

	private validateErrors(error: string) {
		switch (true) {
			case error?.includes('cant get person by phone'):
				this.contactControl.setErrors({ notFound: true });
				break;
			case error.includes('cant generate whatsapp dc'):
				this.isError = true;
				break;
			default:
				break;
		}
	}
}
