import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'splitTestVersion'
})
export class SplitTestVersionPipe implements PipeTransform {
	translate = inject(TranslateService);
	transform(value: string): string {
		const [label, winnerLabel] = value.split('/');

		switch (label) {
			case 'A':
			case 'B':
			case 'C':
			case 'D':
			case 'E':
				return `${this.translate.instant(
					'message_detail_statistics.split_test_version'
				)} ${value}`;
			case 'winner': {
				let result = this.translate.instant(
					'message_detail_statistics.split_test_winner_version'
				);
				if (winnerLabel) {
					result += ` (${winnerLabel})`;
				}
				return result;
			}
			default:
				return value;
		}
	}
}
