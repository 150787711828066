import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	Inject,
	OnInit,
	QueryList,
	TemplateRef,
	Input,
	EventEmitter,
	Output,
	ChangeDetectorRef,
	OnDestroy,
	ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PaginationResponse, PaginatorPlugin } from '@datorama/akita';
import { EnTempalateDirective } from '@shared';
import {
	ActivatedStatus,
	DeleteData,
	MessagesService,
	MessagesState,
	Message,
	FiltersMessage,
	StatusType,
	RelatedMessages
} from '@enSend/message/_states/_state-message';
import { TableColumn } from '@enkod-core/interfaces';

import { ConfirmationService, SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { combineLatest, Observable, BehaviorSubject, Subject } from 'rxjs';
import {
	debounceTime,
	filter,
	finalize,
	map,
	skip,
	startWith,
	switchMap,
	tap
} from 'rxjs/operators';

import {
	SearchService,
	REORDER_COLUMNS_TOKEN,
	TableColumnsSettingsService,
	getMessageTableColumnsWithSettings,
	TableToolsService
} from 'ui-lib';

import { AuthQuery } from '@enkod-core/authentication/_state';
import { Access } from '@enkod-core/authentication/models';
import { TABLE_COLS_TOKEN } from '@enkod-core/tokens';
import { MessagesTab, MessageType } from '@enSend/message/kit';

import { UrlParams, UrlSaverService } from '@enkod-core/services';
import { TagsValue } from '@enkod-core/enums';
import {
	WhatsAppTemplate,
	WhatsAppTemplatesService
} from '@enSend/message/_states/_state-whatsapp-template';
import { PaginatorComponent } from 'ui-lib/paginator/paginator.component';
import { MESSAGES_PAGINATOR } from './message-paginator';
import {
	MESSAGE_TABLE_COLUMNS,
	SCENARIO_TABLE_COLUMNS,
	WHATSAPP_TABLE_COLUMNS
} from './columns';
import {
	STATUS_OPTIONS,
	STATUS_SCENARIO_OPTIONS
} from './message-filter/constants';
import { STATUS_WHATSAPP_OPTIONS } from './message-filter/constants/status-whatsapp-options';

@UntilDestroy()
@Component({
	selector: 'en-message-table',
	templateUrl: './message-table.component.html',
	styleUrls: ['./message-table.component.scss'],
	providers: [
		{
			provide: TABLE_COLS_TOKEN,
			useValue: MESSAGE_TABLE_COLUMNS,
			multi: true
		},
		{
			provide: TABLE_COLS_TOKEN,
			useValue: SCENARIO_TABLE_COLUMNS,
			multi: true
		},
		{
			provide: TABLE_COLS_TOKEN,
			useValue: WHATSAPP_TABLE_COLUMNS,
			multi: true
		},
		{
			provide: REORDER_COLUMNS_TOKEN,
			deps: [TABLE_COLS_TOKEN],
			useFactory: getMessageTableColumnsWithSettings
		},
		PaginatorComponent
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageTableComponent
	implements OnInit, AfterContentInit, OnDestroy
{
	constructor(
		@Inject(MESSAGES_PAGINATOR)
		public paginatorRef: PaginatorPlugin<Message>,
		private confirmationService: ConfirmationService,
		private router: Router,
		private translate: TranslateService,
		private searchService: SearchService,
		public tableToolsService: TableToolsService,
		private tableColumnsSettingsService: TableColumnsSettingsService,
		private messagesService: MessagesService,
		private whatsAppTemplatesService: WhatsAppTemplatesService,
		@Inject(REORDER_COLUMNS_TOKEN)
		public columns$: Observable<TableColumn[]>,
		private urlService: UrlSaverService,
		private authQuery: AuthQuery,
		private cd: ChangeDetectorRef
	) {}

	private filterItemsPull: Array<keyof FiltersMessage> = [
		'channel',
		'sendingTime',
		'sendingType',
		'status',
		'tags',
		'scenarios',
		'noDraft'
	];

	perPageControl: UntypedFormControl;
	toolsTemplate: TemplateRef<any>;

	total = 0;
	titleSplitTest = TagsValue.SPLIT_TEST;

	messages: MessagesState[] = [];
	tab: MessagesTab;

	filterItems: Array<keyof FiltersMessage> = [];
	hideFilters: Array<keyof FiltersMessage>;

	messages$: Observable<PaginationResponse<MessagesState>>;
	sort$: BehaviorSubject<{ field: string; order: number }>;
	filterModel$ = new BehaviorSubject({});
	filters$: BehaviorSubject<any> = new BehaviorSubject(null);
	columnsManual$ = new BehaviorSubject<TableColumn[]>([]);
	tabChanged$ = new Subject<string>();
	resetPage$ = new Subject<string>();

	urlParams: UrlParams;
	isFirstLoad = true;
	isFirstFiltersChange = true;

	checkTemplateData: RelatedMessages;
	isModalShow = false;

	isModalShowMessage = false;
	relatedMessages: DeleteData[];

	@Input() toolsPanel = true;
	@Input() previewLink = true;
	@Input() selection = false;

	@Input() set usePolling(value: boolean) {
		this.messagesService.pollingConfig.usePolling = value;
		this.whatsAppTemplatesService.pollingConfig.usePolling = value;
	}

	@Input() set pollingPeriod(value: number) {
		this.messagesService.pollingConfig.pollingPeriod = value;
		this.whatsAppTemplatesService.pollingConfig.pollingPeriod = value;
	}

	@Input('filters')
	set filtersSetter(value: FiltersMessage) {
		this.updateFilterModel(value);
	}

	@Input('hideFilters')
	set hideFiltersSetter(value: Array<keyof FiltersMessage>) {
		this.hideFilters = value;
		this.updateFilterItems();
	}

	@Input() enReorder = true;

	@Input() messageFilters = true;

	@Input() statusOptions: SelectItem<StatusType>[];

	@Input() notSaveInUrl = false;

	@Input() hideDeleted = false;

	@Input() hideWhatsapp = false;

	@Input('tab')
	set tabSetter(tab: MessagesTab) {
		if (!tab) return;
		this.tab = tab;
		this.setFilterByTab();
		this.resetPage();
		this.tabChanged$.next(tab.location);
		if (!this.enReorder) {
			this.columnsManual$.next(this.tab.columns || MESSAGE_TABLE_COLUMNS);
		}
	}

	@Input() customChannelOptions: MessageType[];

	@Output() selected = new EventEmitter<Message>();

	get isActive(): boolean {
		return this.tableToolsService.rowStatus === 'active';
	}

	get wasActivated(): boolean {
		return (
			this.tab.location !== 'scenario' &&
			this.tableToolsService.rowStatus === 'active'
		);
	}

	get wasInactivated(): boolean {
		return (
			this.tab.location !== 'scenario' &&
			this.tableToolsService.rowStatus === 'inactive'
		);
	}

	get channelType(): string {
		return this.tableToolsService.rowType;
	}

	get sendingType(): string {
		return this.tableToolsService.rowSendingType;
	}

	get canEdit(): boolean {
		const status = this.tableToolsService.rowStatus;
		const editableStatuses =
			this.sendingType === 'sendnow'
				? ['draft', 'inactive', 'active']
				: ['draft', 'inactive', 'waiting', 'active'];
		const canEditByStatus = editableStatuses.includes(status);

		if (this.tab.location === 'whatsapp')
			return this.authQuery.isAccessWhatsApp && canEditByStatus;

		switch (this.channelType) {
			case Access.SMS:
				return this.authQuery.isAccessSms && canEditByStatus;
			case Access.MAIL:
				return this.authQuery.isAccessMail && canEditByStatus;
			case 'push':
				return this.authQuery.isAccessWebPush && canEditByStatus;
			case Access.MOBPUSH:
				return this.authQuery.isAccessMobPush && canEditByStatus;
			default:
				return canEditByStatus;
		}
	}

	get canCopy(): boolean {
		if (
			this.tab.location === 'whatsapp' &&
			!this.authQuery.isAccessWhatsApp
		)
			return false;
		if (this.tableToolsService.rowStatus === 'template_deleted')
			return false;
		switch (this.channelType) {
			case Access.SMS:
				return this.authQuery.isAccessSms;
			case Access.MAIL:
				return this.authQuery.isAccessMail;
			case 'push':
				return this.authQuery.isAccessWebPush;
			case Access.MOBPUSH:
				return this.authQuery.isAccessMobPush;
			case 'whatsapp':
				return this.authQuery.isAccessWhatsApp;
			default:
				return true;
		}
	}

	get canDelete(): boolean {
		return this.tableToolsService.rowStatus !== 'pending';
	}

	get canActivate(): boolean {
		// типы отправки, в которых влияет подключен/отключен ли канал в аккаунте
		const sendingTypes: string[] = ['api', 'regular', 'event', 'doi'];

		switch (this.channelType) {
			case Access.SMS:
				if (sendingTypes.includes(this.sendingType))
					return this.authQuery.isAccessSms && this.wasInactivated;
				return this.wasInactivated;
			case Access.MAIL:
				if (sendingTypes.includes(this.sendingType))
					return this.authQuery.isAccessMail && this.wasInactivated;
				return this.wasInactivated;
			case 'push':
				if (sendingTypes.includes(this.sendingType))
					return (
						this.authQuery.isAccessWebPush && this.wasInactivated
					);
				return this.wasInactivated;
			case 'mobPush':
				if (sendingTypes.includes(this.sendingType))
					return (
						this.authQuery.isAccessMobPush && this.wasInactivated
					);
				return this.wasInactivated;
			default:
				return this.wasInactivated;
		}
	}

	get canCancel(): boolean {
		switch (this.tableToolsService.rowSendingType) {
			case 'sendnow':
				return (
					this.tableToolsService.rowStatus === 'sending' ||
					this.tableToolsService.rowStatus === 'choosingWinner' ||
					this.tableToolsService.rowStatus === 'testing'
				);
			case 'sendlate':
				return (
					this.tableToolsService.rowStatus === 'waiting' ||
					this.tableToolsService.rowStatus === 'sending' ||
					this.tableToolsService.rowStatus === 'choosingWinner' ||
					this.tableToolsService.rowStatus === 'testing'
				);
			default:
				return false;
		}
	}

	get showAlert(): boolean {
		return this.tableToolsService.rowAlert;
	}

	get tooltipVisible(): boolean {
		return this.tableToolsService.tooltipVisible;
	}

	get tabLocation() {
		return this.tab.location;
	}

	get relatedTemplates(): string[] | undefined {
		return this.checkTemplateData?.messages;
	}

	get isAdmin() {
		return this.authQuery.isAdmin;
	}

	@ViewChild('paginator', { static: true })
	paginatorComponent: PaginatorComponent;

	@ContentChildren(EnTempalateDirective) templates: QueryList<any>;

	ngOnInit() {
		this.urlParams = this.urlService.getQueryParams();
		this.init();
		this.searchService.selectRefresh
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.paginatorRef.refreshCurrentPage();
			});

		this.searchService.selectSearchValue
			.pipe(untilDestroyed(this), debounceTime(200), skip(1))
			.subscribe(() => {
				this.resetPage();
			});

		this.messages$
			.pipe(
				untilDestroyed(this),
				map(value => {
					return {
						...value,
						data: value.data.map(data => {
							if (data.tags) {
								const arrTags = [...data.tags].sort(
									(prev: string, next: string) => {
										if (prev === this.titleSplitTest)
											return -1;
										if (next === this.titleSplitTest)
											return 1;
										return 0;
									}
								);
								return {
									...data,
									tags: arrTags
								};
							}
							return data;
						})
					};
				})
			)
			.subscribe(value => {
				this.messages = value.data;
				this.total = value.total || 0;
				this.cd.markForCheck();
			});

		if (!this.enReorder) {
			this.columns$ = this.columnsManual$;
			this.columnsManual$.next(
				this.tab
					? this.tab.columns || MESSAGE_TABLE_COLUMNS
					: MESSAGE_TABLE_COLUMNS
			);
		}
	}

	private init() {
		this.paginatorRef.clearCache();
		const sortByValue: string =
			this.paginatorRef.metadata.get('sortBy') || 'id';
		this.sort$ = new BehaviorSubject({
			field: this.urlParams.sortKey || sortByValue,
			order: Number(this.urlParams.sortOrder) || -1
		});

		const perPageInit = this.paginatorComponent.perPageValue;
		this.perPageControl = new UntypedFormControl(perPageInit);
		const perPage$ = this.perPageControl.valueChanges.pipe(
			startWith(perPageInit)
		);

		if (this.urlParams.search)
			this.searchService.setSearchValue(this.urlParams.search);
		const search$ = this.searchService.selectSearchValue;

		this.messages$ = combineLatest([
			this.paginatorRef.pageChanges,
			combineLatest([
				perPage$.pipe(
					tap(() => {
						this.resetPage();
					})
				),
				this.filters$.pipe(
					filter(f => f),
					// Скрываем удаленные вотсап шаблоны
					map(filters => {
						if (this.hideDeleted) {
							return {
								...filters,
								status: ['active', 'inactive']
							};
						}
						return {
							...filters,
							channel:
								this.customChannelOptions ?? filters.channel
						};
					})
				),
				this.sort$,
				search$
			]).pipe(
				tap(() => {
					this.paginatorRef.clearCache();
					if (this.isFirstLoad) {
						this.isFirstLoad = false;
						this.paginatorRef.setPage(
							Number(this.urlParams.page) || 1
						);
					}
				})
			)
		]).pipe(
			untilDestroyed(this),
			switchMap(filters => {
				const reqFn = () => {
					if (
						this.tab?.location &&
						this.tab.location === 'whatsapp'
					) {
						return this.whatsAppTemplatesService.getList(
							filters,
							this.notSaveInUrl
						);
					}
					return this.messagesService.getList(
						filters,
						this.notSaveInUrl,
						this.hideWhatsapp ? ['whatsapp'] : undefined
					);
				};
				if (this.messagesService.pollingConfig.usePolling) {
					this.paginatorRef.clearCache();
				}
				return this.paginatorRef.getPage(reqFn);
			})
		) as
			| Observable<PaginationResponse<Message>>
			| Observable<PaginationResponse<WhatsAppTemplate>>;
	}

	reorderColumns(columns: TableColumn[]) {
		this.tab.location === 'message'
			? this.tableColumnsSettingsService.reorder(
					columns,
					MESSAGE_TABLE_COLUMNS
			  )
			: this.tableColumnsSettingsService.reorder(
					columns,
					SCENARIO_TABLE_COLUMNS
			  );
	}

	ngAfterContentInit() {
		this.templates.forEach(item => {
			switch (item.getType()) {
				case 'tools':
					this.toolsTemplate = item.template;
					break;

				default:
					break;
			}
		});
	}

	tryChangeStatus(id: number, status: ActivatedStatus): void {
		this.tableToolsService.hideTools();
		if (status === 'active') this.confirmChangeStatus(id, status);
		else this.changeStatus(id, status);
	}

	private changeStatus(id: number, status: ActivatedStatus) {
		this.messagesService
			.changeStatus({
				id,
				status: this.reverseStatus(status)
			})
			.pipe(untilDestroyed(this))
			.subscribe(() => this.paginatorRef.refreshCurrentPage());
	}

	private confirmChangeStatus(id: number, status: ActivatedStatus) {
		this.confirmationService.confirm({
			key: 'confirmAction',
			message: this.translate.instant('message_list.confirm_inactive'),
			header: this.translate.instant('confirm_delete_dialog.header'),
			acceptLabel: this.translate.instant(
				'message_list.accept_label_inactive'
			),
			rejectLabel: this.translate.instant(
				'message_list.reject_label_cancel'
			),
			accept: () => this.changeStatus(id, status)
		});
	}

	private reverseStatus(status: ActivatedStatus): boolean {
		if (status === 'active') return false;
		return true;
	}

	goToDetails() {
		this.tableToolsService.hideTools();
		this.urlService.saveCurrentUrl();
	}

	checkTabLocation(id: number, from: string = 'list') {
		const messagesDetail = `/ensend/messages/detail/${id}`;
		switch (this.tabLocation) {
			case 'whatsapp':
				return this.checkWhatsapp(id, from, messagesDetail);
			default:
				return messagesDetail;
		}
	}

	private checkWhatsapp(id: number, from: string, messagesDetail: string) {
		const whatsappDetail = `/ensend/messages/detail/whatsapp/${id}`;
		return from === 'relatedMessagesModal'
			? messagesDetail
			: whatsappDetail;
	}

	confirmEditMessage({
		id,
		type,
		status
	}: {
		id: number;
		type: string;
		status: StatusType;
	}): void {
		if (
			type === 'mail' &&
			(status === 'active' ||
				status === 'inactive' ||
				status === 'sending')
		) {
			this.confirmationService.confirm({
				key: 'confirmLeave',
				header: this.translate.instant('message_list.header_edit'),
				message: this.translate.instant('message_list.message_info'),
				acceptLabel: this.translate.instant(
					'message_list.accept_label_continue'
				),
				rejectLabel: this.translate.instant(
					'message_list.reject_label_cancel'
				),
				accept: () => {
					this.editMessage(id, type);
				}
			});
		} else {
			this.editMessage(id, type);
		}
	}

	editMessage(id: number, type: string): void {
		this.urlService.saveCurrentUrl();
		this.tableToolsService.hideTools();

		if (this.tab.location === 'whatsapp') {
			this.redirectToTemplateWizard(id, type, 'edit');
			return;
		}

		this.router.navigateByUrl(
			`/ensend/messages/edit/${this.tab.location}/${type}/${+id}`
		);
	}

	private redirectToTemplateWizard(
		id: number,
		type: string,
		action: 'edit' | 'copy'
	): void {
		const url = `/ensend/messages/${action}/${
			type === 'media' ? 'media-' : ''
		}template/${id}`;

		this.router.navigateByUrl(url);
	}

	copyMessageOrTemplate({ id, type }: { id: number; type: string }) {
		this.urlService.saveCurrentUrl();
		this.tableToolsService.hideTools();

		if (this.tab.location === 'whatsapp') {
			this.redirectToTemplateWizard(id, type, 'copy');
			return;
		}

		this.router.navigateByUrl(
			`/ensend/messages/copy/${this.tab.location}/${type}/${+id}`
		);
	}

	cancelSentMessage(id: string): void {
		this.confirmationService.confirm({
			key: 'confirmAction',
			message: this.translate.instant('message_list.confirm_cancel'),
			header: this.translate.instant('confirm_cancel_dialog.header'),
			acceptLabel: this.translate.instant(
				'confirm_cancel_dialog.accept_label'
			),
			rejectLabel: this.translate.instant(
				'confirm_cancel_dialog.reject_label'
			),
			accept: () =>
				this.messagesService
					.cancelMessage(id)
					.pipe(untilDestroyed(this))
					.subscribe(() => {
						this.tableToolsService.hideTools();
						this.paginatorRef.refreshCurrentPage();
					})
		});
	}

	deleteMessageOrTemplate(deleteData: DeleteData): void {
		const deleteMessageTextHeader = 'confirm_delete_dialog.header';
		const deleteTemplateTextHeader =
			'confirm_delete_dialog.header_whatsapp_template';
		const deleteMessageText = 'message_list.confirm_delete';
		const deleteTemplateText =
			'message_list.confirm_delete_whatsapp_template';

		const reqFn = () => {
			if (this.tab.location === 'whatsapp') {
				return this.whatsAppTemplatesService
					.deleteTemplate(deleteData)
					.pipe(untilDestroyed(this))
					.subscribe(() => this.paginatorRef.refreshCurrentPage());
			}
			return this.messagesService
				.deleteMessage(deleteData)
				.subscribe(() => this.paginatorRef.refreshCurrentPage());
		};
		this.paginatorRef.setLoading(true);
		if (this.tab.location === 'whatsapp') {
			this.whatsAppTemplatesService
				.checkTemplate(deleteData)
				.pipe(
					untilDestroyed(this),
					finalize(() => {
						this.paginatorRef.setLoading(false);
					})
				)
				.subscribe(resp => {
					this.checkTemplateData = resp;
					if (this.checkTemplateData.isRelated) {
						this.isModalShow = true;
						this.cd.markForCheck();
					} else {
						this.confirmDelete(
							deleteTemplateText,
							deleteData,
							reqFn,
							deleteTemplateTextHeader
						);
					}
				});
		} else {
			this.messagesService
				.checkDeleted(deleteData.id)
				.pipe(
					untilDestroyed(this),
					finalize(() => {
						this.paginatorRef.setLoading(false);
					})
				)
				.subscribe(resp => {
					if (resp.length) {
						this.urlService.saveCurrentUrl();
						this.relatedMessages = resp;
						this.isModalShowMessage = true;
					} else {
						this.confirmDelete(
							deleteMessageText,
							deleteData,
							reqFn,
							deleteMessageTextHeader
						);
					}
				});
		}
	}

	confirmDelete(
		message: string,
		deleteData: DeleteData,
		reqFn: any,
		header: string
	) {
		this.confirmationService.confirm({
			key: 'confirmDelete',
			message: this.translate.instant(message, {
				id: deleteData.id,
				name:
					deleteData.name ||
					this.translate.instant('message_list.confirm_noname')
			}),
			header: this.translate.instant(header),
			accept: () => reqFn()
		});
	}

	closeModal() {
		this.isModalShow = false;
	}

	deleteMessage = (deleteData: DeleteData): void => {
		this.messagesService
			.deleteMessage(deleteData)
			.pipe(untilDestroyed(this))
			.subscribe(() => this.paginatorRef.refreshCurrentPage());
	};

	deleteTemplate = (deleteData: DeleteData): void => {
		this.whatsAppTemplatesService
			.deleteTemplate(deleteData)
			.subscribe(() => this.paginatorRef.refreshCurrentPage());
	};

	statusOptionsHandler = () => {
		if (this.statusOptions) {
			return this.statusOptions;
		}

		if (this.tab?.location === 'scenario') {
			return STATUS_SCENARIO_OPTIONS;
		}

		if (this.tab?.location === 'whatsapp') {
			return STATUS_WHATSAPP_OPTIONS;
		}

		return STATUS_OPTIONS;
	};

	onModelChange(model: FiltersMessage) {
		this.filters$.next(model);
		if (!this.isFirstFiltersChange) {
			this.resetPage();
			return;
		}
		this.isFirstFiltersChange = false;
	}

	private updateFilterModel(filterModel: any = {}) {
		this.filterModel$.next(filterModel);
	}

	private setFilterByTab() {
		const { filter, hideFilters } = this.tab;
		const { tags, sendingTime } = this.filters$.value || {};

		this.updateFilterItems(hideFilters);
		this.updateFilterModel({
			...filter,
			// для сохранения контролов при переключении вкладок
			...(tags ? { tags } : {}),
			...(sendingTime ? { sendingTime } : {})
		});
	}

	private updateFilterItems(items: string[] = []) {
		const arr = new Set([...(this.hideFilters || []), ...items]);
		this.filterItems = this.filterItemsPull.filter(item => !arr.has(item));
	}

	// Метод для сброса страницы, random нужен для работы сеттера в пагинаторе
	private resetPage() {
		if (!this.isFirstLoad) this.resetPage$.next(Math.random().toString());
	}

	trackByFn(_index: number, item: any) {
		return item.id;
	}

	ngOnDestroy(): void {
		this.messagesService.destroyPolling();
	}
}
