import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { WhatsAppTemplate } from './whatsapp-template.model';

export interface WhatsAppTemplatesState extends EntityState<WhatsAppTemplate> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'whatsapp-template'
})
export class WhatsAppTemplatesStore extends EntityStore<WhatsAppTemplatesState> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {
		super();
	}
}
