import { ID } from '@datorama/akita';

export type Channel = 'email' | 'push' | 'phone';

export interface MailingGroup {
	id: ID;
	name: string;
	systemName: string;
	description: string;
	createDate: number;
	channel: Channel;
	messageId: ID;
	messageName?: string;

	displayName: string;
	isDeleted: boolean;
	removeDate: number;
	totalSubscribers?: number;
	notConfirmedCount?: number;
}
