import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Inject,
	Input,
	Optional,
	Self,
	ViewChild
} from '@angular/core';

import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import codemirror, { Position } from 'codemirror';
import 'codemirror/addon/scroll/simplescrollbars';

import { AbstractControl, NgControl } from '@angular/forms';
import { EMPTY_FUNCTION } from '@enkod-core/constants';

@Component({
	selector: 'en-code-area',
	templateUrl: './code-area.component.html',
	styleUrls: ['./code-area.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: []
})
export class EnCodeAreaComponent implements AfterViewInit {
	dcVisible: boolean = false;
	hasPosition: boolean = false;
	position: Position;

	onChange = EMPTY_FUNCTION;
	onTouched = EMPTY_FUNCTION;

	options: codemirror.EditorConfiguration = {
		lineNumbers: true,
		mode: 'htmlmixed',
		scrollbarStyle: 'simple'
	};

	@Input() useOutlineColor: boolean = true;

	@ViewChild('codemirror')
	private readonly codeMirrorComp: CodemirrorComponent;

	constructor(
		@Optional()
		@Self()
		@Inject(NgControl)
		private ngControl: NgControl | null
	) {
		if (this.ngControl) this.ngControl.valueAccessor = this;
	}

	get control(): AbstractControl | null {
		if (this.ngControl) {
			return this.ngControl.control;
		}
		return null;
	}

	get codeMirror() {
		return this.codeMirrorComp.codeMirror;
	}

	get focused(): boolean {
		return this.codeMirrorComp?.isFocused;
	}

	ngAfterViewInit() {
		this.codeMirror?.setSize('100%', '100%');
	}

	registerOnChange(fn: Function) {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function) {
		this.onTouched = fn;
	}

	writeValue(): void {}

	onFocused() {
		this.position = this.codeMirror!.getCursor();
		this.hasPosition = true;
	}

	updateHTML(value: string) {
		const scrollInfo = this.codeMirror?.getScrollInfo();

		if (this.codeMirror) {
			const symbolIndexFromCursor = this.codeMirror.indexFromPos(
				this.position
			);

			const leftSideOfText = this.control?.value.slice(
				0,
				symbolIndexFromCursor
			);
			const rightSideOfText = this.control?.value.slice(
				symbolIndexFromCursor
			);
			const updatedText = `${leftSideOfText} ${value} ${rightSideOfText}`;

			this.control?.patchValue(updatedText);

			this.codeMirror.scrollTo(scrollInfo?.left, scrollInfo?.top);
			this.codeMirror.setCursor(this.position);
			this.codeMirror.focus();
		}
	}
}
