import { Provider } from '@angular/core';

import { VALUE } from '@enkod-core/tokens';
import {
	DYNAMIC_CONTENT_LISTS,
	DYNAMIC_CONTENT_LISTS_SMS,
	DYNAMIC_CONTENT_LISTS_WHATSAPP,
	DYNAMIC_CONTENT_LISTS_API_QUERY_BLOCK
} from './dynamic-content';

export const OPTIONS_PROVIDER: Provider[] = [
	{
		provide: VALUE,
		useValue: {
			mail: DYNAMIC_CONTENT_LISTS,
			sms: DYNAMIC_CONTENT_LISTS_SMS,
			whatsapp: DYNAMIC_CONTENT_LISTS_WHATSAPP,
			apiQueryBlock: DYNAMIC_CONTENT_LISTS_API_QUERY_BLOCK
		}
	}
];

export interface DynamicContentLists {
	options: ListOptions[];
	title: string;
}

export interface ListOptions {
	label: string;
	value: string;
}

export interface DCListsProvider {
	mail: DynamicContentLists[];
	sms: DynamicContentLists[];
	whatsapp: DynamicContentLists[];
	apiQueryBlock: DynamicContentLists[];
}

export type DCLocation = 'mail' | 'sms' | 'whatsapp' | 'apiQueryBlock';
