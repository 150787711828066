import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TuiMapperPipeModule } from '@taiga-ui/cdk';
import { TranslateModule } from '@ngx-translate/core';

import { DisableControlDirectiveModule } from '@enkod-core/directives';
import { ConvertDateModule } from '@enkod-core/pipes';
import { SharedModule } from '@shared';
import {
	EnTableModule,
	PaginatorModule,
	EnButtonModule,
	EnReorderModule,
	EnDropdownModule,
	EnMultiSelectModule,
	EnStatusTagModule,
	EnTagModule,
	EnDropdownFilterCalendarModule,
	EnConfirmDialogModule,
	EnTooltipModule,
	HorizontalScrollWrapperModule,
	RelatedDataDialogModule
} from 'ui-lib';
import { EnsendSharedModule } from 'app/modules/enSend/_shared/ensend-shared.module';
import { EnDialogModule } from 'ui-lib/dialog/dialog';
import { MessageTableComponent } from './message-table.component';
import { MessageFilterComponent } from './message-filter/message-filter.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		TuiMapperPipeModule,
		DisableControlDirectiveModule,
		EnTableModule,
		PaginatorModule,
		EnsendSharedModule,
		EnButtonModule,
		EnReorderModule,
		TranslateModule,
		EnDropdownModule,
		EnDropdownFilterCalendarModule,
		EnMultiSelectModule,
		EnStatusTagModule,
		EnTagModule,
		EnConfirmDialogModule,
		HorizontalScrollWrapperModule,
		EnTooltipModule,
		ConvertDateModule,
		EnDialogModule,
		RelatedDataDialogModule
	],
	declarations: [MessageTableComponent, MessageFilterComponent],
	exports: [MessageTableComponent]
})
export class MessageTableModule {}
