<p-sidebar [(visible)]="visible"
           [fullScreen]="true"
           [showCloseIcon]="false"
           appendTo="body"
           (onHide)="closePreview()">
    <div class="header">
        <div class="header__title">{{ 'whatsapp_message_wizard.preview_title' | translate }}</div>
        <button class="h32 en-button-secondary"
                enButton
                type="button"
                [label]="'message_wizard_edit_step.sendtest_button_back' | translate"
                (click)="closePreview()"></button>
    </div>

    <form class="content"
          [formGroup]="form"
          (ngSubmit)="submit()">

        <div *ngIf="!isError"
             class="content__preview">
            <en-whatsapp-preview [form]="form"
                                 [loadBeforeInit]="true"></en-whatsapp-preview>
        </div>

        <div *ngIf="isError"
             class="error">
            <en-small [visible]="isError"
                      severity="validate"
                      [detail]="'whatsapp_message_wizard.dc_preview_error' | translate">
            </en-small>
        </div>


        <tui-scrollbar>
            <div class="content__editor">
                <div class="editor__contact">
                    <div class="en-field large">
                        <label for="input-contact">{{ 'message_wizard_edit_step.content_sms_title' | translate
                            }}</label>
                        <input class="h36"
                               enInput
                               pKeyFilter="num"
                               maxlength="11"
                               id="input-contact"
                               [placeholder]="'whatsapp_message_wizard.phone_placeholder' | translate"
                               [formControl]="contactControl">
                        <en-error [formControl]="contactControl"
                                  mode="advanced"></en-error>
                    </div>
                    <div [enTooltip]="disableGenerating && ('message_wizard_edit_step.tooltip_text' | translate)"
                         tooltipPosition="left">
                        <button class="h36 en-button-secondary button-generate"
                                enButton
                                type="submit"
                                [label]="'message_wizard_edit_step.button_email_generate' | translate"
                                [disabled]="disableGenerating"></button>
                    </div>
                </div>

                <div class="editor__history">
                    <div class="editor__history-header">
                        <div class="editor__history-title">
                            {{ 'message_wizard_edit_step.editor_history_title' | translate }}
                        </div>
                        <button enButton
                                class="en-button-lowercase"
                                type="button"
                                (click)="removeAllContacts()"
                                [label]="'message_wizard_edit_step.button_clear_all_emails' | translate"></button>
                    </div>

                    <button *ngFor="let contact of contactsHistory$ | async"
                            class="button-history"
                            type="button"
                            (click)="selectContact(contact.body)">
                        {{ contact.contact }}
                        <i class="en en-cancel"
                           (click)="removeContact(contact.id)"></i>
                    </button>
                </div>

            </div>
        </tui-scrollbar>
    </form>
</p-sidebar>