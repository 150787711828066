import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	Inject,
	Optional,
	Self,
	Output,
	EventEmitter,
	ViewChild
} from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import IMask from 'imask';
import { DropdownCalendarComponent } from 'ui-lib/dropdown-calendar-new/dropdown-calendar.component';

@UntilDestroy()
@Component({
	selector: 'en-field-input',
	templateUrl: './field-input.component.html',
	styleUrls: ['./field-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class FieldInputComponent implements OnInit {
	value: string | number;
	_type: string;

	numberMask = IMask.createMask({
		mask: Number,
		scale: 0,
		normalizeZeros: true,
		thousandsSeparator: ' ',
		min: -999999999,
		max: 999999999
	});

	floatMask = IMask.createMask({
		mask: Number,
		scale: 9,
		normalizeZeros: true,
		radix: '.',
		mapToRadix: [','],
		thousandsSeparator: ' ',
		min: -999999999,
		max: 999999999
	});

	onTouched: Function = () => {};

	onChange: Function = () => {};

	@Input() boolDefaultValue: SelectItem[] = [
		{ label: this.translate.instant('boolean.true'), value: true },
		{ label: this.translate.instant('boolean.false'), value: false }
	];

	@Input()
	set type(value: string) {
		this._type = value;
		this.clearControl();
	}

	@Input() control: UntypedFormControl = new UntypedFormControl(null);

	@Input() styleClass: string;

	@Input() appendTo = 'body';

	/** Костыль для нормальной работы супертултипа */
	@Input() dateCalendarBodyAppend = false;

	@Input() disable = false;

	@Input() inputId: string | null = null;

	@Input() isValid = true;

	@Input() placeholder: string = '';

	@Input() telMask: string = '';

	@Output() emitIgnoredElement = new EventEmitter();

	@Output() onTypeChange = new EventEmitter();

	@ViewChild('calendar') calendar: DropdownCalendarComponent;

	get type() {
		return this._type;
	}

	constructor(
		@Optional()
		@Self()
		@Inject(NgControl)
		private ngControl: NgControl | null,
		private translate: TranslateService
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
	}

	ngOnInit(): void {
		this.control.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					if (value === '' || value === 0) {
						this.onChange(null);
						return;
					}
					if (value !== null) {
						this.onChange(value);
					}
					this.onTypeChange.emit();
				})
			)
			.subscribe();
	}

	registerOnChange(onChange: Function) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: Function) {
		this.onTouched = onTouched;
	}

	writeValue(value: string | number) {
		this.value = value;

		this.control.patchValue(value);
	}

	private clearControl() {
		this.control.patchValue(null);
		if (this.calendar) this.calendar.resetCalendar();
	}
}
