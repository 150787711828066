import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClicksMapAction, ClicksMapModifier } from '../clicks-map.model';

@Injectable()
export class ClicksMapState {
	inited = false;
	visible = false;
	modifier = new BehaviorSubject<ClicksMapModifier>('');
	action = new BehaviorSubject<ClicksMapAction>('init');
}
