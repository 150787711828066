import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TuiScrollbarModule } from '@taiga-ui/core';
import { SidebarModule } from 'primeng/sidebar';
import {
	EnInputModule,
	EnButtonModule,
	ErrorModule,
	EnTooltipModule,
	EnDialogModule,
	EnSmallModule
} from 'ui-lib';
import { WhatsappPreviewModule } from '@enSend/message/message-wizard/kit';
import { KeyFilterModule } from 'primeng/keyfilter';
import { WhatsappDcPreviewComponent } from './whatsapp-dc-preview.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		WhatsappPreviewModule,
		EnInputModule,
		TranslateModule,
		EnButtonModule,
		SidebarModule,
		ErrorModule,
		TuiScrollbarModule,
		EnTooltipModule,
		EnDialogModule,
		KeyFilterModule,
		EnSmallModule
	],
	declarations: [WhatsappDcPreviewComponent],
	exports: [WhatsappDcPreviewComponent]
})
export class WhatsappDcPreviewModule {}
