<div class="tools">
    <en-message-filter *ngIf="messageFilters"
                       [items]="filterItems"
                       [statusOptionsHandler]="statusOptionsHandler"
                       [customChannelOptions]="customChannelOptions"
                       [hideWhatsapp]="hideWhatsapp"
                       [tabChanged]="tabChanged$ | async"
                       [ngModel]="filterModel$ | async"
                       (ngModelChange)="onModelChange($event)"></en-message-filter>

    <ng-container *ngTemplateOutlet="toolsTemplate"></ng-container>

    <en-paginator #paginator
                  class="paginator"
                  [total]="total"
                  [paginatorRef]="paginatorRef"
                  [onLoadFromUrl]="!notSaveInUrl"
                  [resetPage]="resetPage$ | async"
                  [formControl]="perPageControl"></en-paginator>

    <en-reorder *ngIf="enReorder"></en-reorder>
</div>

<p-table [columns]="columns$ | async"
         [value]="messages"
         [rowTrackBy]="trackByFn"
         [resizableColumns]="true"
         [reorderableColumns]="enReorder"
         [customSort]="true"
         [lazy]="true"
         [rowHover]="true"
         [loading]="paginatorRef.isLoading$ | async"
         [sortField]="urlParams.sortKey"
         [sortOrder]="urlParams.sortOrder"
         (onSort)="sort$.next($event)"
         (onColReorder)="reorderColumns($event.columns)">
    <ng-template pTemplate="header"
                 let-columns>
        <tr class="table-header">
            <th *ngFor="let col of columns"
                [pSortableColumn]="col.field"
                [pSortableColumnDisabled]="col.sortableDisabled"
                pResizableColumn
                pReorderableColumn
                [pReorderableColumnDisabled]="col.reorderableDisabled || !enReorder"
                [style.width]="col.width"
                [style.min-width]="col['min-width']"
                valign="middle">
                <div class="en-header-column-wrapper">
                    <span class="text-overflow">{{ col.header | translate}}</span>
                    <p-sortIcon *ngIf="!col.sortableDisabled"
                                [field]="col.field"></p-sortIcon>
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body"
                 let-rowData
                 let-columns="columns"
                 let-index="rowIndex">
        <tr (mouseenter)="toolsPanel && tableToolsService.showTools(index, $event, rowData)"
            (mouseleave)="toolsPanel && tableToolsService.hideTools()"
            class="visible-selection-button"
            [ngClass]="{ 'alert-mark': rowData?.alert }">
            <td *ngFor="let col of columns"
                [class.p-reorderable-column]="!col.reorderableDisabled"
                [class.en-cell-no-padding]="col.field==='name'"
                [class.line-height-100]="selection"
                style="max-width: 0px">
                <ng-container [ngSwitch]="col.field">

                    <ng-container *ngIf="isAdmin">
                        <ng-template ngSwitchCase="id">
                            <span [class.has-html]="rowData.hasHtml">{{ rowData[col.field] }}</span>
                        </ng-template>
                    </ng-container>

                    <ng-template ngSwitchCase="name">
                        <div class="en-table-name">
                            <ng-container *ngIf="previewLink; else withoutLink">
                                <a class="en-table-name__name-block en-table-name__preview-link"
                                   [routerLink]="[checkTabLocation(rowData.id)]"
                                   (click)="goToDetails()">
                                    <span class="text-overflow">
                                        {{ rowData[col.field] ||
                                        '(' + ('message_list.table_message_no_name' | translate) + ')' }}
                                    </span>
                                </a>
                            </ng-container>

                            <ng-template #withoutLink>
                                <div class="en-table-name__name-block">
                                    <span class="text-overflow">
                                        {{ rowData[col.field] ||
                                        '(' + ('message_list.table_message_no_name' | translate) + ')' }}
                                    </span>
                                </div>
                            </ng-template>

                            <div class="en-table-name__selection"
                                 *ngIf="selection">
                                <button type="button"
                                        enButton
                                        class="h24 en-button-secondary"
                                        [label]="'common.to_select'| translate"
                                        (click)="selected.emit(rowData)"></button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngSwitchCase="type">
                        <div class="message-type">
                            <i *ngIf="!rowData.category"
                               [ngClass]="rowData[col.field] | typeIcon"
                               class="table-icon"></i>
                            {{ rowData[col.field] | channelName }}
                        </div>
                    </ng-template>
                    <ng-template ngSwitchCase="sendingTime">
                        <ng-template [ngIf]="rowData[col.field] > 0">
                            {{ rowData[col.field] | convertDate : 'DD.MM.yyyy, HH:mm' }}
                        </ng-template>
                    </ng-template>
                    <ng-template ngSwitchCase="sendingType">
                        {{ rowData[col.field] | sendingType }}
                    </ng-template>
                    <ng-template ngSwitchCase="status">
                        <en-status-tag [label]="rowData[col.field] | sendingStatusLabel | translate"
                                       [status]="rowData[col.field] | sendingStatusCondition"
                                       [showIcon]="false"
                                       [showLoader]="true">
                        </en-status-tag>
                    </ng-template>
                    <ng-template ngSwitchCase="scenarioName"
                                 *ngIf="rowData.scenarioId > 0">
                        <div class="text-overflow">
                            [id {{ rowData.scenarioId }}] {{ rowData.scenarioName }}
                        </div>
                    </ng-template>
                    <ng-template ngSwitchCase="tags">
                        <horizontal-scroll-wrapper>
                            <en-tag *ngFor="let tag of rowData[col.field]"
                                    [value]="tag | systemTag"
                                    [defaultValue]="tag"
                                    showTooltip="true"
                                    status="message">
                            </en-tag>
                        </horizontal-scroll-wrapper>
                    </ng-template>

                    <ng-template ngSwitchDefault>
                        {{ rowData[col.field] }}
                    </ng-template>
                </ng-container>
            </td>
        </tr>
        <div *ngIf="toolsPanel"
             class="tools-box"
             [ngStyle]="{ top: tableToolsService.offsetTop, height: tableToolsService.trHeight }"
             [ngClass]="{ 'show': tableToolsService.tool && index === tableToolsService.chosenTr,
                          'inactive': index !== tableToolsService.chosenTr }"
             (mouseenter)="tableToolsService.showTools(index);"
             (mouseleave)="tableToolsService.hideTools();">
            <div *ngIf="index === tableToolsService.chosenTr"
                 class="alert__group">
                <span *ngIf="showAlert"
                      class="en en-exclamation-triangle-o alert-icon"
                      [enTooltip]="('table_tools.tooltip_alert' | translate)"
                      tooltipPosition="left"
                      tooltipStyleClass="alert__tooltip"
                      showDelay="5"
                      [showNow]="tooltipVisible"></span>
            </div>
            <en-button *ngIf="canCancel"
                       [title]="('table_tools.tools_button_cancel' | translate)"
                       styleClass="en-button-lowercase h32"
                       iconSrc="enIconCancel"
                       (onClick)="cancelSentMessage(rowData.id)"></en-button>
            <en-button *ngIf="canActivate"
                       [title]="'table_tools.tools_button_activate' | translate"
                       styleClass="en-button-lowercase h32"
                       [icon]="'en en-play-circle-o'"
                       (onClick)="tryChangeStatus(rowData.id, rowData?.status)"></en-button>
            <en-button *ngIf="wasActivated"
                       [title]="'table_tools.tools_button_pause' | translate"
                       styleClass="en-button-lowercase h32"
                       [icon]="'en en-pause-circle-o'"
                       (onClick)="tryChangeStatus(rowData.id, rowData?.status)"></en-button>
            <en-button *ngIf="canEdit"
                       [title]="'table_tools.tools_button_edit' | translate"
                       styleClass="en-button-lowercase h32"
                       icon="en en-pencil-o"
                       (onClick)="confirmEditMessage({ id: rowData.id, type: rowData.type, status: rowData?.status })"></en-button>
            <en-button *ngIf="canCopy"
                       [title]="'table_tools.tools_button_copy' | translate"
                       styleClass="en-button-lowercase h32"
                       icon="en en-copy"
                       (onClick)="copyMessageOrTemplate({ id: rowData.id, type: rowData.type })"></en-button>
            <en-button *ngIf="canDelete"
                       [title]="'table_tools.tools_button_delete' | translate"
                       styleClass="en-button-lowercase h32"
                       icon="en en-trash"
                       (onClick)="deleteMessageOrTemplate({id: rowData.id, name: rowData.name})"></en-button>
        </div>

    </ng-template>
    <ng-template pTemplate="emptymessage"
                 let-columns>
        <tr>
            <td [attr.colspan]="columns?.length">
                <div class="empty-table">
                    <h3>{{'common.empty_table' | translate}}</h3>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>

<en-dialog [header]="'message_list.unable_to_delete' | translate"
           [modal]="true"
           [(visible)]="isModalShow"
           [headerBackground]="'danger'"
           [headerIcon]="'en en-exclamation-circle-o'">
    <div class="message">
        <span>
            {{'message_list.confirm_can_not_delete_whatsapp_template' | translate}}
        </span>
        <div class="message-list"
             *ngFor="let template of relatedTemplates">
            <a [routerLink]="[checkTabLocation(template.id, 'relatedMessagesModal')]"
               (click)="goToDetails()">
                <span class="id-badge en-mr-2">id {{ template.id }}</span>
                <span class="name">
                    {{template.name}}
                </span>
            </a>
        </div>
    </div>
    <p-footer>
        <button type="button"
                enButton
                class="h36 en-button-secondary"
                [style]="{'margin-right': '1rem'}"
                [label]="'confirm_delete_dialog.reject_label' | translate"
                (click)="closeModal()">
        </button>
        <button type="button"
                enButton
                class="h36 en-button-solid"
                [label]="'confirm_delete_dialog.accept_label' | translate"
                [disabled]="true">
        </button>
    </p-footer>
</en-dialog>

<en-related-data-dialog *ngIf="isModalShowMessage"
                        [dialogVisible]="isModalShowMessage"
                        [navigateUrl]="'/ensend/scenario/detail/'"
                        [infoText]="'message_list.related_messages_text'"
                        [relatedData]="relatedMessages"
                        (closeDialog)="isModalShowMessage = false"></en-related-data-dialog>