import { Injectable } from '@angular/core';

import { CRUDService } from '@enkod-core/abstract';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { WhatsAppTemplate } from './whatsapp-template.model';

@Injectable({
	providedIn: 'root'
})
export class WhatsAppTemplatesDataService extends CRUDService<WhatsAppTemplate> {
	constructor() {
		super('ensend/whatsapp/template/');
	}

	getAll(
		checkLoad?: boolean
	): Observable<{ result: WhatsAppTemplate[]; total: number }> {
		let params;
		if (checkLoad) {
			params = new HttpParams({
				fromObject: { limit: '10', offset: '0' }
			});
		}
		return this.http.get<{ result: WhatsAppTemplate[]; total: number }>(
			'ensend/whatsapp/template/',
			{ params }
		);
	}
}
