import { TableColumn } from '@enkod-core/interfaces';

const minWidth = '90px';

export const MESSAGE_TABLE_COLUMNS: TableColumn[] = [
	{
		field: 'id',
		header: 'message_list.table_header_id',
		reorderableDisabled: true,
		width: '4.5rem',
		'min-width': minWidth
	},
	{
		field: 'name',
		header: 'message_list.table_header_name',
		width: '28.5rem',
		'min-width': minWidth
	},
	{
		field: 'type',
		header: 'message_list.table_header_type',
		width: '9.75rem',
		'min-width': minWidth
	},
	{
		field: 'sendingTime',
		header: 'message_list.table_header_sending_time',
		width: '11.75rem',
		'min-width': minWidth
	},
	{
		field: 'sendingType',
		header: 'message_list.table_header_sending_type',
		width: '185px',
		'min-width': minWidth
	},
	{
		field: 'status',
		header: 'message_list.table_header_sending_status',
		width: '10rem',
		'min-width': minWidth
	},
	{
		field: 'tags',
		header: 'message_list.table_header_tags',
		width: '30.75rem',
		'min-width': minWidth,
		sortableDisabled: true
	}
];

export const SCENARIO_TABLE_COLUMNS: TableColumn[] = [
	{
		field: 'id',
		header: 'message_list.table_header_id',
		reorderableDisabled: true,
		width: '4.5rem',
		'min-width': minWidth
	},
	{
		field: 'name',
		header: 'message_list.table_header_name',
		width: '28.5rem',
		'min-width': minWidth
	},
	{
		field: 'type',
		header: 'message_list.table_header_type',
		width: '9.75rem',
		'min-width': minWidth
	},
	{
		field: 'sendingTime',
		header: 'message_list.table_header_sending_last_time',
		width: '11.75rem',
		'min-width': minWidth
	},
	{
		field: 'status',
		header: 'message_list.table_header_sending_status',
		width: '10rem',
		'min-width': minWidth
	},
	{
		field: 'tags',
		header: 'message_list.table_header_tags',
		width: '30.75rem',
		'min-width': minWidth,
		sortableDisabled: true
	}
];

export const WHATSAPP_TABLE_COLUMNS: TableColumn[] = [
	{
		field: 'id',
		header: 'message_list.table_header_id',
		reorderableDisabled: true,
		width: '2.5rem',
		'min-width': minWidth
	},
	{
		field: 'name',
		header: 'message_list.table_header_template_name',
		width: '18.5rem',
		'min-width': minWidth
	},
	{
		field: 'type',
		header: 'message_list.table_header_template_type',
		width: '9rem',
		'min-width': minWidth
	},
	{
		field: 'status',
		header: 'message_list.table_header_sending_status',
		width: '8rem',
		'min-width': minWidth
	},
	{
		field: 'tags',
		header: 'message_list.table_header_tags',
		width: '12rem',
		'min-width': minWidth,
		sortableDisabled: true
	}
];
