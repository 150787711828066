import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Message } from './message.model';

export interface MessagesState extends EntityState<Message> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'messages'
})
export class MessagesStore extends EntityStore<MessagesState> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {
		super();
	}
}
