import { ID } from '@datorama/akita';
import { AccountModel } from '@state-admin/accounts/accounts-list';
import { Language } from '@state-user-settings';

export enum Access {
	POPUP = 'popup',
	MAIL = 'mail',
	SMS = 'sms',
	DATATABLES = 'dataTables',
	ENRECOM = 'enRecom',
	MOBPUSH = 'mobPush',
	WEBPUSH = 'webPush',
	TRACKING = 'tracking',
	MOBTRACKING = 'mobTracking',
	WHATSAPP = 'whatsapp',
	SMTP = 'smtp',
	SMTPINTERFACE = 'smtpInterface',
	CHATBOT = 'telegramBot'
}
export interface JWT {
	// TODO: подробнее типизация
	access: Access[];
	accountId: number;
	accountName: string;
	aud: string;
	exp: number;
	iss: string;
	login: string;
	orig_iat: number;
	// TODO: подробнее типизация
	roles: string[];
	status: string;
	userId: number;
	settings: {
		language: Language;
	};
}

export enum UserRoles {
	USER = 'user',
	SUPER_ADMIN = 'admin',
	OWNER = 'owner'
}

export interface UserModel {
	id: ID;
	userId: number;
	login: string;
	firstName: string;
	lastName: string;
	role: UserRoles;
	pass?: string;
	// settings?: SettingsModel;
	status: 'active' | 'inactive';
	accounts: (ID | AccountModel)[];
	responsibleForPayment?: boolean;
	// pending?: boolean;
}

export interface Metadata {
	firstName: string;
	lastName: string;
	role: UserRoles;
	status: 'active' | 'inactive';
	accounts: AccountModel[];
}

export interface LoginResponse {
	accessToken: string;
	refreshToken: string;
	exp: number;
	metadata: Metadata;
	limitSettings: {
		baseLimit: number;
	};
	timeZone: string;
	enableCustomId: boolean;
	isFirstLogin: boolean;
}

export type Service = 'enSend' | 'enPop' | 'enRecom';
