<div [formGroup]="form"
     class="filters">

    <span>{{ 'message_list.show_text' | translate }}</span>

    <!-- Канал -->
    <div [hidden]="isVisible('channel')">
        <en-multiSelect [disableControl]="isVisible('channel')"
                        class="w200"
                        styleClass="en-multiselect-filter en-width"
                        [options]="getItemOptions('channel')"
                        formControlName="channel"
                        [isClearButton]="true"
                        [showHeader]="false"
                        (onClear)="remove('channel')">
            <ng-template let-value
                         pTemplate="selectedItems">
                <div style="display: flex;">
                    <span class="text-overflow"
                          *ngIf="value?.length > 0">
                        ({{ value.length }}) {{ 'channel' | declinationWord:value.length | translate }}

                    </span>
                </div>
                <div *ngIf="!value || value.length === 0"
                     class="en-multiselected-empty-token en-placeholder">
                    {{ 'message_list.all_channel' | translate }}
                </div>
            </ng-template>
        </en-multiSelect>
    </div>

    <!-- Сценарий -->
    <div [hidden]="isVisible('scenarios')">
        <en-multiSelect class="w320"
                        styleClass="en-multiselect-filter en-width"
                        [options]="getItemOptions('scenarios') | async"
                        [itemSize]="34"
                        [isClearButton]="true"
                        [showToggleAll]="false"
                        [virtualScroll]="true"
                        [usePagination]="true"
                        [showCloseButton]="false"
                        [customSearch]="true"
                        [placeholder]="'message_list.all_scenario' | translate"
                        [showCloseButton]="false"
                        (loadOptions)="updateScenariosParams($event)"
                        (onSearch)="onScenariosSearch($event)"
                        formControlName="scenarios">
            <ng-template let-value
                         pTemplate="selectedItems">
                <div style="display: flex;">
                    <span class="text-overflow"
                          *ngIf="value?.length > 0">
                        ({{ value.length }}) {{ 'scenario' | declinationWord:value.length | translate }}
                    </span>
                </div>
                <div *ngIf="!value || value.length === 0"
                     class="en-multiselected-empty-token en-placeholder">
                    {{ 'message_list.all_scenario' | translate }}
                </div>
            </ng-template>
            <ng-template let-scenario
                         pTemplate="item">
                <div class="option-condition-group">
                    <span class="id-badge">id {{ scenario.id }}</span>
                    <span class="scenario-name"> {{ scenario.name }}</span>
                </div>
            </ng-template>
        </en-multiSelect>
    </div>


    <!-- Статус -->
    <div [hidden]="isVisible('status')">
        <en-multiSelect [disableControl]="isVisible('status')"
                        class="w200"
                        styleClass="en-multiselect-filter en-width"
                        [options]="statusOptionsHandler('status')"
                        formControlName="status"
                        [isClearButton]="true"
                        [showHeader]="false"
                        (onClear)="remove('status')">
            <ng-template let-value
                         pTemplate="selectedItems">
                <div style="display: flex;">
                    <span class="text-overflow"
                          *ngIf="value?.length > 0">
                        ({{ value.length }}) {{ 'status' | declinationWord:value.length | translate }}

                    </span>
                </div>
                <div *ngIf="!value || value.length === 0"
                     class="en-multiselected-empty-token en-placeholder">
                    {{ 'message_list.filters_all_statuses' | translate }}
                </div>
            </ng-template>
        </en-multiSelect>
    </div>

    <!-- Условия отправки -->
    <div
         [hidden]="((form.get('sendingType')?.value && form.get('sendingType')?.value[0]) === 'scenario') || isVisible('sendingType')">
        <en-multiSelect [disableControl]="isVisible('sendingType')"
                        class="w200"
                        styleClass="en-multiselect-filter en-width"
                        [options]="getItemOptions('sendingType')"
                        formControlName="sendingType"
                        [isClearButton]="true"
                        [showHeader]="false"
                        (onClear)="remove('sendingType')">
            <ng-template let-value
                         pTemplate="selectedItems">
                <div style="display: flex;">
                    <span class="text-overflow"
                          *ngIf="value?.length > 0">
                        ({{ value.length }}) {{ 'condition' | declinationWord:value.length | translate }}

                    </span>
                </div>
                <div *ngIf="!value || value.length === 0"
                     class="en-multiselected-empty-token en-placeholder">
                    {{ 'message_list.filter_send_condition' | translate }}
                </div>
            </ng-template>
        </en-multiSelect>
    </div>

    <!-- Период отправки -->
    <div [hidden]="isVisible('sendingTime')">
        <en-dropdown-filter-calendar [disableControl]="isVisible('sendingTime')"
                                     dropDownAlign="right"
                                     [period]="'past'"
                                     [dateRange]="dateRange"
                                     formControlName="sendingTime"
                                     class="h32">
        </en-dropdown-filter-calendar>
    </div>


    <!-- Тег -->
    <div [hidden]="isVisible('tags')">
        <en-multiSelect [disableControl]="isVisible('tags')"
                        class="w320"
                        styleClass="en-multiselect-filter en-width"
                        [options]="getItemOptions('tags') | async"
                        [isClearButton]="true"
                        [showToggleAll]="false"
                        [showCloseButton]="false"
                        formControlName="tags"
                        (onClear)="remove('tags')">
            <ng-template let-value
                         pTemplate="selectedItems">
                <div style="display: flex;">
                    <span class="text-overflow"
                          *ngIf="value?.length > 0">
                        ({{ value.length }}) {{ 'tag' | declinationWord:value.length | translate }}

                    </span>
                </div>
                <div *ngIf="!value || value.length === 0"
                     class="en-multiselected-empty-token en-placeholder">
                    {{ 'message_list.filter_tag' | translate }}
                </div>
            </ng-template>
            <ng-template let-tag
                         pTemplate="item">
                <div class="tag__item_wrapper">
                    <div class="tag__item">{{tag.value | systemTag }}</div>
                </div>
            </ng-template>
        </en-multiSelect>
    </div>
</div>