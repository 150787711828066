import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sendingStatusLabel'
})
export class SendingStatusPipe implements PipeTransform {
	transform(value: string): string {
		switch (value) {
			case 'waiting':
				return 'message_list.status_waiting';
			case 'sending':
				return 'message_list.status_sending';
			case 'sent':
				return 'message_list.status_sent';
			case 'draft':
				return 'message_list.status_draft';
			case 'active':
				return 'message_list.status_active';
			case 'inactive':
				return 'message_list.status_inactive';
			case 'finished':
				return 'message_list.status_finished';
			case 'choosingWinner':
				return 'message_list.status_choosing_winner';
			case 'testing':
				return 'message_list.status_testing';
			case 'stopped':
				return 'message_list.status_stopped';
			case 'approved':
				return 'message_list.status_approved';
			case 'rejected':
				return 'message_list.status_rejected';
			case 'pending':
				return 'message_list.status_pending';
			case 'template_deleted':
				return 'message_list.status_template_deleted';
			default:
				return value;
		}
	}
}
