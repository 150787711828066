import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'messageType'
})
export class MessageTypePipe implements PipeTransform {
	transform(value: string): string {
		switch (value) {
			case 'mail':
				return 'емейл';
			case 'push':
				return 'push';
			case 'mobPush':
				return 'mob-push';
			case 'telegram':
				return 'telegram';
			case 'sms':
				return 'sms';
			case 'whatsapp':
				return 'whatsapp';
			case 'viber':
				return 'viber';
			default:
				return value;
		}
	}
}
