import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'sendingType'
})
export class SendingTypePipe implements PipeTransform {
	constructor(private translate: TranslateService) {}

	transform(value: string): string {
		switch (value?.toLowerCase()) {
			case 'sendnow':
				return this.translate.instant('message_types.sendnow');
			case 'sendlate':
				return this.translate.instant('message_types.sendlate');
			case 'regular':
				return this.translate.instant('message_types.regular');
			case 'api':
				return this.translate.instant('message_types.api');
			case 'event':
				return this.translate.instant('message_types.event');
			case 'doi':
				return this.translate.instant('message_types.doi');
			case 'scenario':
				return this.translate.instant('message_types.scenario');
			default:
				return value;
		}
	}
}
