import { Injectable } from '@angular/core';

import { ExpandedQuery } from '@enkod-core/abstract';
import { ALL_LIST_STORE_NAME } from '@enkod-core/constants';
import { Observable } from 'rxjs';

import { MailingGroup } from './mailing-group.model';
import { MailingGroupsStore, MailingGroupsState } from './mailing-groups.store';

@Injectable({ providedIn: 'root' })
export class MailingGroupsQuery extends ExpandedQuery<MailingGroupsState> {
	constructor(protected store: MailingGroupsStore) {
		super(store);
	}

	readonly groups$ = this.selectAll() as Observable<MailingGroup[]>;

	readonly allGroups$ = this.select(ALL_LIST_STORE_NAME) as Observable<
		MailingGroup[]
	>;

	readonly allDoiGroups$ = this.select('allDoiList') as Observable<
		MailingGroup[]
	>;
}
