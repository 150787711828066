import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Inject,
	Input,
	Optional,
	Self,
	ChangeDetectorRef
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	NgControl,
	NgModel
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tuiDefaultProp, TuiHandler, tuiPure } from '@taiga-ui/cdk';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { debounceTime, map, take, tap } from 'rxjs/operators';

import { EMPTY_FUNCTION } from '@enkod-core/constants';
import { UrlSaverService } from '@enkod-core/services';
import {
	FiltersMessage,
	MessageType,
	StatusType
} from '@enSend/message/_states/_state-message';
import { Scenario } from '@enSend/scenario/_state';
import { EnsendSharedQuery, EnsendSharedService } from '@state-enSend/_shared';

import { CHANNEL_OPTIONS, STATUS_OPTIONS, TYPE_OPTIONS } from './constants';

@UntilDestroy()
@Component({
	selector: 'en-message-filter',
	templateUrl: './message-filter.component.html',
	styleUrls: ['./message-filter.component.scss'],
	providers: [EnsendSharedService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageFilterComponent<T extends FiltersMessage>
	implements OnInit
{
	private previousInternalValue?: T | null;
	private onTouched = EMPTY_FUNCTION;
	private onChange = EMPTY_FUNCTION;

	form: UntypedFormGroup = this.fb.group({
		channel: null,
		sendingTime: null,
		sendingType: null,
		tags: null,
		status: null,
		scenarios: null
	});

	items: Array<keyof FiltersMessage>;

	scenarioListOption$ = this.sharedService.scenarios$.pipe(
		untilDestroyed(this),
		map(scenario =>
			scenario
				? scenario.result.map((scenario: Scenario) => ({
						id: scenario.id,
						name: scenario.name,
						value: scenario.id
				  }))
				: []
		)
	) as Observable<SelectItem[]>;

	optionsMap: { [key in keyof Partial<FiltersMessage>]: any } = {
		channel: CHANNEL_OPTIONS,
		sendingType: TYPE_OPTIONS,
		tags: this.sharedQuery.tagsWithoutBot$,
		scenarios: this.scenarioListOption$
	};

	dateRange: [number, number];

	@Input() customChannelOptions: MessageType[];

	@Input()
	set tabChanged(location: string) {
		this.form.patchValue({
			sendingType: location === 'scenario' ? 'scenario' : null,
			status: null,
			channel: this.customChannelOptions ?? null
		});
	}

	@Input() hideWhatsapp = false;

	@Input('items')
	@tuiDefaultProp()
	set itemsSetter(value: Array<keyof FiltersMessage>) {
		this.items = value;
		this.form.updateValueAndValidity();
	}

	@Input()
	@tuiDefaultProp()
	statusOptionsHandler: TuiHandler<any, SelectItem<StatusType>[]> = () =>
		STATUS_OPTIONS;

	constructor(
		public sharedQuery: EnsendSharedQuery,
		private sharedService: EnsendSharedService,
		private fb: UntypedFormBuilder,
		@Optional()
		@Self()
		@Inject(NgControl)
		private readonly ngControl: NgControl | null,
		@Inject(ChangeDetectorRef)
		private readonly changeDetectorRef: ChangeDetectorRef,
		private urlService: UrlSaverService
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
	}

	get value(): T {
		return this.previousInternalValue || ({} as T);
	}

	get isScenario() {
		return (
			(this.form.get('sendingType')?.value &&
				this.form.get('sendingType')?.value[0] === 'scenario') ||
			this.form.get('sendingType')?.value === 'scenario'
		);
	}

	ngOnInit(): void {
		const dateRange = this.urlService.getQueryParams();
		const { startDate, endDate } = dateRange;
		const filters =
			this.customChannelOptions ??
			this.urlService.convertStringParamsToArrays();

		this.sharedService.getTags();
		this.sharedService
			.getScenarios()
			.pipe(untilDestroyed(this))
			.subscribe();
		if (this.ngControl?.valueChanges) {
			this.ngControl.valueChanges
				.pipe(
					untilDestroyed(this),
					take(1),
					tap(value => {
						this.form.reset(value);
						this.form.patchValue({
							...filters,
							sendingTime: [startDate, endDate]
						});
						if (startDate && endDate) {
							this.dateRange = [startDate, endDate];
						}
						this.checkControlUpdate();
					})
				)
				.subscribe();
		}

		this.form.valueChanges
			.pipe(
				untilDestroyed(this),
				debounceTime(120),
				tap(value => {
					this.updateValue({ ...this.value, ...value });
				})
			)
			.subscribe();
	}

	writeValue(value: T | null) {
		const controlValue =
			this.ngControl instanceof NgModel &&
			this.previousInternalValue === undefined
				? this.ngControl.model
				: value;

		this.refreshLocalValue(controlValue);
	}

	registerOnChange(onChange: (value: T | unknown) => void) {
		this.onChange = (componentValue: T) => {
			onChange(componentValue);
		};
	}

	registerOnTouched(onTouched: () => void) {
		this.onTouched = onTouched;
	}

	checkControlUpdate() {
		this.changeDetectorRef.markForCheck();
	}

	private refreshLocalValue(value: T | undefined) {
		this.previousInternalValue = value;
		this.checkControlUpdate();
	}

	private updateValue(value: T) {
		if (this.valueIdenticalComparator(this.value, value)) {
			return;
		}

		this.previousInternalValue = value;
		this.controlSetValue(value);
	}

	private valueIdenticalComparator(oldValue: T, newValue: T): boolean {
		return JSON.stringify(oldValue) === JSON.stringify(newValue);
	}

	private controlSetValue(value: T) {
		this.onChange(value);
		this.checkControlUpdate();
	}

	onScenariosSearch(event: string) {
		this.sharedService.onSearch(event);
	}

	updateScenariosParams(event: number) {
		if (event >= this.sharedService.queryParams$.getValue().limit) {
			this.sharedService.updateParams();
		}
	}

	@tuiPure
	isVisible(filterName: keyof FiltersMessage): boolean {
		return !this.items.includes(filterName);
	}

	@tuiPure
	getItemOptions(
		item: keyof FiltersMessage
	): SelectItem[] | Observable<SelectItem[]> {
		if (item === 'channel' && this.isScenario)
			return this.optionsMap.channel.filter(
				(option: SelectItem<MessageType>) =>
					option.value !== 'telegram' && option.value !== 'push'
			);

		if (item === 'channel' && this.hideWhatsapp)
			return this.optionsMap.channel.filter(
				(option: SelectItem<MessageType>) => option.value !== 'whatsapp'
			);

		return this.optionsMap[item];
	}

	remove(filterName: string) {
		this.form.controls[filterName].patchValue(null);
	}
}
