import {
	WaTemplateParamsDTO,
	WaTemplateParamsOTD
} from '@enSend/message/whatsapp-template-wizard/models';

export class WhatsappParamsDTO {
	params: WaTemplateParamsDTO;

	constructor(value: WaTemplateParamsOTD, titleType?: string) {
		const convertedParams: WaTemplateParamsDTO = {};
		if (value?.body_text?.length)
			convertedParams.body_text = [value.body_text];

		if (value?.footer_text?.length)
			convertedParams.footer_text = [value.footer_text];

		if (value?.header_text?.length)
			convertedParams.header_text = value.header_text;

		if (value?.url_button_params?.length)
			convertedParams.url_button_params = value.url_button_params;

		if (value?.header_handle?.length && titleType)
			switch (titleType) {
				case 'IMAGE':
					convertedParams.header_image = value.header_handle;
					break;
				case 'DOCUMENT':
					convertedParams.header_document = value.header_handle;
					break;
				case 'VIDEO':
					convertedParams.header_video = value.header_handle;
					break;
				default:
					break;
			}

		Object.assign(this, { params: convertedParams });
	}

	getValue(): WaTemplateParamsDTO {
		return this.params;
	}
}
