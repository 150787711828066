import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tuiAssert } from '@taiga-ui/cdk';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { NotificationAlert } from 'app/ui-lib/notification/modules/notification-alert/Notification-alert';
import { NotificationContentContext } from 'app/ui-lib/notification/modules/notifications-content-context';
import {
	NotificationOptionsWithData,
	NotificationTokenOptions,
	NOTIFICATION_OPTIONS,
	NotificationOptions
} from 'app/ui-lib/notification/notification.model';
import { BehaviorSubject, Observable, Observer } from 'rxjs';

const NO_HOST =
	'Notifications are disabled, enable support by adding NotificationsModule to your main app module';
@Injectable({
	providedIn: 'root'
})
export class NotificationsService {
	readonly items$ = new BehaviorSubject<
		ReadonlyArray<NotificationAlert<any, any>>
	>([]);

	constructor(
		@Inject(NOTIFICATION_OPTIONS)
		public readonly options: NotificationTokenOptions,
		private translate: TranslateService
	) {}

	show<O = void>(
		content: PolymorpheusContent<NotificationContentContext<O>>
	): Observable<O>;
	show<O = void>(
		content: PolymorpheusContent<NotificationContentContext<O>>,
		options: NotificationOptions
	): Observable<O>;
	show<O, I>(
		content: PolymorpheusContent<NotificationContentContext<O, I>>,
		options: NotificationOptions | NotificationOptionsWithData<I> = {}
	): Observable<O> {
		return new Observable((observer: Observer<O>) => {
			tuiAssert.assert(!!this.items$.observers.length, NO_HOST);

			const localParams: Record<string, any> = options.params
				? { ...options.params }
				: {};

			const contentToTranslate =
				!options.params && typeof content === 'string'
					? this.shortenText(content)
					: content;

			if (typeof localParams.name === 'string') {
				localParams.name = this.shortenText(localParams.name);
			}

			const translateContent =
				typeof contentToTranslate === 'string' &&
				contentToTranslate !== ''
					? this.translate.instant(contentToTranslate, localParams)
					: contentToTranslate;

			const finalOptions = {
				...this.options,
				...options,
				params: localParams
			};

			const notification = new NotificationAlert(
				observer,
				translateContent,
				finalOptions
			);
			this.items$.next([...this.items$.value, notification]);

			return () => {
				this.items$.next(
					this.items$.value.filter(item => item !== notification)
				);
			};
		});
	}

	private shortenText(text: string): string {
		const MAX_LENGTH = 165;
		const SUBSTRING_LENGTH = 120;
		return text.length > MAX_LENGTH
			? `${text.substring(0, SUBSTRING_LENGTH).trim()}...`
			: text;
	}
}
