import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sendingStatusCondition'
})
export class SendingStatusConditionPipe implements PipeTransform {
	transform(value: string): string {
		switch (value) {
			case 'waiting':
				return 'pause';
			case 'sending':
				return 'loading';
			case 'sent':
				return 'success';
			case 'draft':
				return 'pause';
			case 'active':
				return 'success';
			case 'inactive':
				return 'error';
			case 'finished':
				return 'success';
			case 'choosingWinner':
				return 'warning';
			case 'testing':
				return 'loading';
			case 'stopped':
				return 'error';
			case 'approved':
				return 'success';
			case 'rejected':
				return 'error';
			case 'pending':
				return 'loading';
			case 'template_deleted':
				return 'error';
			default:
				return value;
		}
	}
}
