import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from 'utils';
import { Observable } from 'rxjs';

import { ID } from '@datorama/akita';
import { TemplateData } from '@enSend/message/message-wizard/message-wizard-common/step-content/2-step-editor/email-editor/email-editor.component';
import { ChangeStatus, DeleteData, Message, PlainText } from './message.model';
import {
	MessageModel,
	TestMail,
	TestSms,
	TestWhatsapp
} from '../../message-wizard/message-wizard-common/models';
import { MessageType } from '../../message-details/_states/_state-message';

@Injectable({
	providedIn: 'root'
})
export class MessagesDataService extends RestService<Message> {
	constructor(protected http: HttpClient) {
		super(http, 'ensend/message');
	}

	sendTestMail(data: TestMail) {
		return this.http.post<TestMail>('ensend/send/test/', data);
	}

	sendTestSms(data: TestSms) {
		return this.http.post<TestSms>('ensend/send/sms/test/', data);
	}

	sendTestWhatsapp(data: TestWhatsapp) {
		return this.http.post<TestWhatsapp>('ensend/send/whatsapp/test/', data);
	}

	changeStatus({ id, status }: ChangeStatus): Observable<Message> {
		return this.http.put(`ensend/message/${id}`, {
			isActive: status
		}) as Observable<Message>;
	}

	cancelSend(id: string) {
		return this.http.post('ensend/message/stop/', { id });
	}

	getDetail(id: ID) {
		return this.http.get<MessageModel>(`ensend/message/${id}`);
	}

	getMessageUrls(id: ID, type: MessageType): Observable<any> {
		return this.http.get(`ensend/message/urls/${id}/`, {
			params: {
				type
			}
		});
	}

	getTextVersion(html: string): Observable<PlainText> {
		return this.http.put(`ensend/message/plainText/`, {
			html
		}) as Observable<PlainText>;
	}

	checkDeletedData(id: number) {
		return this.http.get(`ensend/message/deleteCheck/${id}`) as Observable<
			DeleteData[]
		>;
	}

	saveTemplate(title: string, data: TemplateData) {
		const payload = { title, ...data };
		return this.http.post('ensend/message/template/mail/', payload);
	}

	logImport(id: ID) {
		return this.http.get('/enkod/import/', {
			params: {
				messageId: id
			}
		});
	}
}
