import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { ID } from '@datorama/akita';
import { ALL_LIST_STORE_NAME } from '@enkod-core/constants';
import { NotificationStatus } from 'ui-lib';
import { NotificationsService, UrlSaverService } from '@enkod-core/services';
import { MailingGroup } from './mailing-group.model';
import { MailingGroupsDataService } from './mailing-groups-data.service';
import { MailingGroupsStore } from './mailing-groups.store';
import { MailingGroupsQuery } from './mailing-groups.query';

@Injectable({ providedIn: 'root' })
export class MailingGroupsService {
	constructor(
		private store: MailingGroupsStore,
		private query: MailingGroupsQuery,
		private dataService: MailingGroupsDataService,
		private notificationsService: NotificationsService,
		private urlService: UrlSaverService
	) {}

	getAllList(): Observable<MailingGroup[]> {
		if (this.query.hasAllEntities()) return this.query.allGroups$;
		return this.requestAllList();
	}

	private requestAllList() {
		return this.dataService.list().pipe(
			catchError(e => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(e);
			}),
			switchMap(resp => {
				this.store.update(() => ({
					[ALL_LIST_STORE_NAME]: resp.result
				}));
				return this.query.allGroups$;
			})
		);
	}

	getList({ page, sort, perPage, search, filter }: any) {
		return this.dataService
			.list({
				limit: perPage,
				offset: (page - 1) * perPage,
				sort,
				search,
				...filter
			})
			.pipe(
				tap(() => {
					this.urlService.setParamsToUrl({
						sortKey: sort?.field,
						sortOrder: sort?.order,
						perPage,
						search
					});
				}),
				switchMap(resp => {
					return of({
						perPage: perPage || 10,
						lastPage: 0,
						currentPage: page,
						total: Math.ceil(resp.total / perPage) || 1,
						data: resp.result ? [...resp.result] : []
					});
				}),
				catchError(() => {
					this.notificationsService
						.show('toast.mailing_groups_no_list', {
							label: 'toast.summary_try_later',
							status: NotificationStatus.ERROR
						})
						.subscribe();
					return of({
						perPage: perPage || 10,
						lastPage: 0,
						currentPage: page,
						total: 1,
						data: []
					});
				})
			);
	}

	// Если doi = false, придет список ГР без doi, doi = true - вернет список с doi
	getDOIList(doi = false): Observable<MailingGroup[]> {
		return this.requestAllDoiList(doi);
	}

	private requestAllDoiList(doi = false) {
		return this.dataService
			.list({
				sortKey: 'id',
				sortOrder: 'desc',
				doi
			})
			.pipe(
				catchError(e => {
					this.notificationsService
						.show('toast.mailing_groups_no_list', {
							label: 'toast.summary_try_later',
							status: NotificationStatus.ERROR
						})
						.subscribe();
					return throwError(e);
				}),
				switchMap(resp => {
					this.store.update(() => ({
						allDoiList: resp.result
					}));
					return this.query.allDoiGroups$;
				})
			);
	}

	getDetail(id: ID): Observable<MailingGroup> {
		return this.dataService.detail(id).pipe(
			tap(resp => this.store.upsert(resp.id, resp)),
			catchError(() => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return of({} as MailingGroup);
			})
		);
	}

	create(entity: MailingGroup) {
		this.store.setLoading(true);
		return this.dataService.create(entity).pipe(
			tap(resp => {
				this.store.add(resp);
				this.clearAllListCache();
			}),
			catchError(error => {
				this.notificationsService
					.show('toast.mailing_groups_create_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(error);
			}),
			finalize(() => this.store.setLoading(false))
		);
	}

	update(entity: MailingGroup) {
		this.store.setLoading(true);
		return this.dataService.update(entity).pipe(
			tap(resp => {
				this.store.update(resp.id, resp);
				this.clearAllListCache();
			}),
			catchError(error => {
				this.notificationsService
					.show('toast.mailing_groups_update_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(error);
			}),
			finalize(() => this.store.setLoading(false))
		);
	}

	delete(entity: MailingGroup) {
		this.store.setLoading(true);
		return this.dataService.remove(entity.id).pipe(
			tap(() => {
				this.clearAllListCache();
				this.store.setLoading(false);
			}),
			catchError(error => {
				this.notificationsService
					.show('toast.mailing_groups_delete_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(error);
			}),
			finalize(() => this.store.setLoading(false))
		);
	}

	removeFromStore(entity: MailingGroup) {
		const allGroups = this.store.getValue().allDoiList || [];
		const newGroups = allGroups.filter(
			(current: MailingGroup) => current.id !== entity.id
		);
		this.store.update(() => ({ allDoiList: newGroups }));
	}

	setActive(id: ID) {
		this.store.setActive(id);
	}

	checkSystemNameMailing(systemName: String): Observable<boolean> {
		return this.dataService
			.checkSystemNameMailing(systemName)
			.pipe(map(value => value.uniqueSystemName));
	}

	checkRelatesMessages(id: number) {
		return this.dataService.checkRelatesMessages(id).pipe(
			catchError(error => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(error);
			})
		);
	}

	clearAllListCache(): void {
		this.store.update(() => ({
			[ALL_LIST_STORE_NAME]: null
		}));

		this.store.update(() => ({
			allDoiList: null
		}));
	}
}
