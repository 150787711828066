import { SelectItem } from 'primeng/api';

export const CONTACT_DATA: SelectItem[] = [
	{
		label: 'dc_manager.subscriber_email',
		value: '{{subscriber_email}}'
	},
	{
		label: 'dc_manager.subscriber_email_md5',
		value: '{{subscriber_email_md5}}'
	},
	{
		label: 'dc_manager.subscriber_email_sha256',
		value: '{{subscriber_email_sha256}}'
	},
	{
		label: 'dc_manager.subscriber_phone',
		value: '{{subscriber_phone}}'
	},
	{
		label: 'dc_manager.subscriber_firstname',
		value: '{{subscriber_firstname}}'
	},
	{
		label: 'dc_manager.subscriber_lastname',
		value: '{{subscriber_lastname}}'
	},
	{
		label: 'dc_manager.subscriber_name',
		value: '{{subscriber_name}}'
	}
];

export const SYSTEM_LINKS: SelectItem[] = [
	{
		label: 'dc_manager.link_unsubscribe',
		value: '{{link_unsubscribe}}'
	},
	{
		label: 'dc_manager.link_unsubscribe_manager',
		value: '{{link_unsubscribe_manager}}'
	},
	{
		label: 'dc_manager.link_view_in_browser',
		value: '{{link_view_in_browser}}'
	}
];

export const MESSAGE_INFO: SelectItem[] = [
	{
		label: 'dc_manager.message_id',
		value: '{{message_id}}'
	},
	{
		label: 'dc_manager.message_guid',
		value: '{{message_guid}}'
	},
	{
		label: 'dc_manager.message_subject',
		value: '{{message_subject}}'
	},
	{
		label: 'dc_manager.message_day',
		value: '{{message_day}}'
	},
	{
		label: 'dc_manager.message_dayofweek',
		value: '{{message_dayofweek}}'
	},
	{
		label: 'dc_manager.message_shortdate',
		value: '{{message_shortdate}}'
	},
	{
		label: 'dc_manager.message_longdate',
		value: '{{message_longdate}}'
	},
	{
		label: 'dc_manager.message_month',
		value: '{{message_month}}'
	},
	{
		label: 'dc_manager.message_monthnumber',
		value: '{{message_monthnumber}}'
	},
	{
		label: 'dc_manager.message_year',
		value: '{{message_year}}'
	}
];

export const SENDER_INFO: SelectItem[] = [
	{
		label: 'dc_manager.sender_fromemail',
		value: '{{sender_fromemail}}'
	},
	{
		label: 'dc_manager.sender_fromname',
		value: '{{sender_fromname}}'
	},
	{
		label: 'dc_manager.sender_replytoemail',
		value: '{{sender_replytoemail}}'
	},
	{
		label: 'dc_manager.sender_replytoname',
		value: '{{sender_replytoname}}'
	}
];

export const CONTACT_DATA_FIELDS: SelectItem[] = [
	{
		label: 'dc_manager.extra_field_name',
		value: '{{extraField.Name}}'
	},
	{
		label: 'dc_manager.is_extra_field_exists',
		value: `{{IsExtraFieldExists('Системное имя поля данных')}}`
	},
	{
		label: 'dc_manager.compare_two_fields',
		value: '<!--{% if (extraField.Name1 >= extraField.Name2) { %}--> Какой-либо текст<!--{% }; %}-->'
	},
	{
		label: 'dc_manager.set_extra_field',
		value: `{{SetExtraField('Name', value)}}`
	},
	{
		label: 'dc_manager.change_field_link',
		value: `<a href="{{SetExtraFieldByClick('https://link.com', 'EF_system_name', 'EF_value')}}">клик</a>`
	},
	{
		label: 'dc_manager.change_value_type',
		value: `<!--{% var a = extraField.weight; var value = String(a); if (value === '60') { %}-->  Вы весите 60 кг <!--{% }; %}-->`
	}
];

export const CONTACT_GR: SelectItem[] = [
	{
		label: 'dc_manager.unsubscribe',
		value: `{{Unsubscribe('groupname', 'groupname2')}}`
	},
	{
		label: 'dc_manager.unsubscribe_redirect',
		value: `{{UnsubscribeRedirect('link', 'groupName', 'groupName2')}}`
	},
	{
		label: 'dc_manager.subscribe_specific_gr',
		value: `{{Subscribe('groupname', 'groupname2')}}`
	},
	{
		label: 'dc_manager.subscribe_specific_gr_redirect',
		value: `{{SubscribeRedirect('link', 'groupName', 'groupName2')}}`
	},
	{
		label: 'dc_manager.confirm_redirect',
		value: `{{ConfirmRedirect('http://mysite.com/')}}`
	}
];

export const DATE_AND_TIME: SelectItem[] = [
	{
		label: 'dc_manager.time_now',
		value: '{{TimeNow()}}'
	},
	{
		label: 'dc_manager.time_parse',
		value: `{{TimeParse('2020-11-25 15:31')}}`
	},
	{
		label: 'dc_manager.add_time',
		value: `{{AddTime(1603459057, 'min', 3)}}`
	},
	{
		label: 'dc_manager.time_format',
		value: `{{TimeFormat(1603459057, '2006-01-02 15:04:05')}}`
	}
];

export const TEXT: SelectItem[] = [
	{
		label: 'dc_manager.base64_encode',
		value: `{{Base64Encode('Текст')}}`
	},
	{
		label: 'dc_manager.base64_decode',
		value: `{{Base64Decode('0KLQtdC60YHRggoK')}}`
	},
	{
		label: 'dc_manager.parse_XML',
		value: `{{ParseXML('XMLcontents')}}`
	}
];

export const EXTERNAL_CONTENT: SelectItem[] = [
	{
		label: 'dc_manager.get_data_from_link',
		value: `{{HttpGet('https://link.com')}}`
	}
];

export const SNIPPETS: SelectItem[] = [
	{
		label: 'dc_manager.snippet',
		value: `{{Snippet('snippetName')}}`
	},
	{
		label: 'dc_manager.is_snippet_exists',
		value: `{{IsSnippetExists('название сниппета')}}`
	},
	{
		label: 'dc_manager.snippet_with_condition',
		value: `<!--{% var value = Snippet('snippetName'); if (value === '10') { %}--> Значение сниппета snippetName 10 <!--{% }else{  Abort()}; %}--> `
	}
];

export const LINKS: SelectItem[] = [
	{
		label: 'dc_manager.do_not_track_link',
		value: `<a href="{{DoNotTrackLink('https://link.com')}}">клик</a>`
	}
];

export const STOP_SENDING_MESSAGE: SelectItem[] = [
	{
		label: 'dc_manager.stop_send',
		value: `{% if ('Какое-либо условие') { %} Какое-либо значение {% } else{ Abort() }; %}`
	},
	{
		label: 'dc_manager.stop_send_dc',
		value: '{{Abort()}}'
	}
];

export const TRIGGER: SelectItem[] = [
	{
		label: 'dc_manager.get_cart_products',
		value: '{% var str = GetCart.Products; str.forEach(function(item, i, arr) { %} {{item.productId}} {% }); %}'
	},
	{
		label: 'dc_manager.get_favourite_products',
		value: '{% var str = GetFavourite.Products; str.forEach(function(item, i, arr) { %} {{item.productId}} {% }); %}'
	},
	{
		label: 'dc_manager.get_comparison_products',
		value: '{% var str = GetComparison().Products; str.forEach(function(item, i, arr) { %} {{item.productId}} {% }); %}'
	},
	{
		label: 'dc_manager.get_opens',
		value: `{{GetOpens(10, Newest, '< 1609888844')}}`
	},
	{
		label: 'dc_manager.get_opened_category_items',
		value: `{{GetOpenedCategoryItems(10,4)}}`
	},
	{
		label: 'dc_manager.get_orders',
		value: `{{GetOrders(100, DESC, 'between 1603459057 and 1603459059')}}`
	},
	{
		label: 'dc_manager.get_order',
		value: `{{GetOrder('orderId')}}`
	}
];

export const DATA_TABLES: SelectItem[] = [
	{
		label: 'dc_manager.get_string',
		value: `{% var rows = Rows().Get('*').Table('table_name').Where('param', equal, 'something').Execute(); %}`
	},
	{
		label: 'dc_manager.result_output_tables',
		value: '{{название переменной[номер строки].название столбца}}'
	}
];

export const SQL: SelectItem[] = [
	{
		label: 'dc_manager.get_by_template',
		value: '{% var name = GetByTemplate(123); %}'
	},
	{
		label: 'dc_manager.get_by_template_with_params',
		value: `{% var name = GetByTemplate(123,{'param':value}); %}`
	},
	{
		label: 'dc_manager.result_output_sql',
		value: '{{name[0].field}}'
	}
];

export const SYSTEM_LINKS_SMS: SelectItem[] = [
	{
		label: 'dc_manager.link_unsubscribe',
		value: '{{link_unsubscribe}}'
	}
];

export const MESSAGE_INFO_SMS: SelectItem[] = [
	{
		label: 'dc_manager.message_id',
		value: '{{message_id}}'
	},
	{
		label: 'dc_manager.message_day',
		value: '{{message_day}}'
	},
	{
		label: 'dc_manager.message_dayofweek',
		value: '{{message_dayofweek}}'
	},
	{
		label: 'dc_manager.message_shortdate',
		value: '{{message_shortdate}}'
	},
	{
		label: 'dc_manager.message_longdate',
		value: '{{message_longdate}}'
	},
	{
		label: 'dc_manager.message_month',
		value: '{{message_month}}'
	},
	{
		label: 'dc_manager.message_monthnumber',
		value: '{{message_monthnumber}}'
	},
	{
		label: 'dc_manager.message_year',
		value: '{{message_year}}'
	}
];

export const CONTACT_DATA_FIELDS_SMS: SelectItem[] = [
	{
		label: 'dc_manager.extra_field_name',
		value: '{{extraField.Name}}'
	},
	{
		label: 'dc_manager.is_extra_field_exists',
		value: `{{IsExtraFieldExists('Системное имя поля данных')}}`
	},
	{
		label: 'dc_manager.compare_two_fields',
		value: '{% if (extraField.Name1 >= extraField.Name2) { %} Какой-либо текст {% }; %}'
	},
	{
		label: 'dc_manager.set_extra_field',
		value: `{{SetExtraField('Name', value)}}`
	},
	{
		label: 'dc_manager.change_field_link',
		value: `{{SetExtraFieldByClick('https://link.com', 'EF_system_name', 'EF_value')}}`
	},
	{
		label: 'dc_manager.change_value_type',
		value: `{% var a = extraField.weight; var value = String(a); if (value === '60') { %}  Вы весите 60 кг {% }; %}`
	}
];

export const CONTACT_GR_SMS: SelectItem[] = [
	{
		label: 'dc_manager.unsubscribe',
		value: `{{Unsubscribe('groupname', 'groupname2')}}`
	},
	{
		label: 'dc_manager.unsubscribe_redirect',
		value: `{{UnsubscribeRedirect('link', 'groupName', 'groupName2')}}`
	},
	{
		label: 'dc_manager.subscribe_specific_gr',
		value: `{{Subscribe('groupname', 'groupname2')}}`
	},
	{
		label: 'dc_manager.subscribe_specific_gr_redirect',
		value: `{{SubscribeRedirect('link', 'groupName', 'groupName2')}}`
	}
];

export const MESSAGE_INFO_WHATSHAPP: SelectItem[] = [
	{ label: 'dc_manager.message_day', value: '{{message_day}}' },
	{ label: 'dc_manager.message_dayofweek', value: '{{message_dayofweek}}' },
	{ label: 'dc_manager.message_shortdate', value: '{{message_shortdate}}' },
	{ label: 'dc_manager.message_longdate', value: '{{message_longdate}}' },
	{ label: 'dc_manager.message_month', value: '{{message_month}}' },
	{
		label: 'dc_manager.message_monthnumber',
		value: '{{message_monthnumber}}'
	},
	{ label: 'dc_manager.message_year', value: '{{message_year}}' }
];

export const DATA_FIELDS_WHATSAPP: SelectItem[] = [
	{ label: 'dc_manager.extra_field_name', value: '{{extraField.Name}}' }
];

export const CONTACT_EXTRA_FIELD_NAME: SelectItem[] = [
	{
		label: 'dc_manager.extra_field_name',
		value: '{{extraField.Name}}'
	}
];

export const TIME_NOW: SelectItem[] = [
	{
		label: 'dc_manager.time_now',
		value: '{{TimeNow()}}'
	}
];

export const SCENARIO: SelectItem[] = [
	{
		label: 'dc_manager.scenario_field',
		value: '{{scenarioField.Name}}'
	}
];

// Стандартный список дк
export const DYNAMIC_CONTENT_LISTS = [
	{ options: CONTACT_DATA, title: 'dc_manager.contact_data' },
	{ options: SCENARIO, title: 'dc_manager.scenario' },
	{ options: SYSTEM_LINKS, title: 'dc_manager.system_links' },
	{ options: MESSAGE_INFO, title: 'dc_manager.message_info' },
	{ options: SENDER_INFO, title: 'dc_manager.sender_info' },
	{ options: CONTACT_DATA_FIELDS, title: 'dc_manager.contact_data_fields' },
	{ options: CONTACT_GR, title: 'dc_manager.contact_gr' },
	{ options: DATE_AND_TIME, title: 'dc_manager.data_and_time' },
	{ options: TEXT, title: 'dc_manager.title_text' },
	{ options: EXTERNAL_CONTENT, title: 'dc_manager.external_content' },
	{ options: SNIPPETS, title: 'dc_manager.title_snippets' },
	{ options: LINKS, title: 'dc_manager.title_links' },
	{ options: STOP_SENDING_MESSAGE, title: 'dc_manager.stop_sending_message' },
	{ options: TRIGGER, title: 'dc_manager.title_trigger' },
	{ options: DATA_TABLES, title: 'dc_manager.data_tables' },
	{ options: SQL, title: 'dc_manager.title_sql' }
];

// ДК для СМС
export const DYNAMIC_CONTENT_LISTS_SMS = [
	{ options: CONTACT_DATA, title: 'dc_manager.contact_data' },
	{ options: SCENARIO, title: 'dc_manager.scenario' },
	{ options: SYSTEM_LINKS_SMS, title: 'dc_manager.system_links' },
	{ options: MESSAGE_INFO_SMS, title: 'dc_manager.message_info' },
	{
		options: CONTACT_DATA_FIELDS_SMS,
		title: 'dc_manager.contact_data_fields'
	},
	{ options: CONTACT_GR_SMS, title: 'dc_manager.contact_gr' },
	{ options: DATE_AND_TIME, title: 'dc_manager.data_and_time' },
	{ options: TEXT, title: 'dc_manager.title_text' },
	{ options: EXTERNAL_CONTENT, title: 'dc_manager.external_content' },
	{ options: STOP_SENDING_MESSAGE, title: 'dc_manager.stop_sending_message' },
	{ options: TRIGGER, title: 'dc_manager.title_trigger' },
	{ options: DATA_TABLES, title: 'dc_manager.data_tables' },
	{ options: SQL, title: 'dc_manager.title_sql' }
];

// ДК для WhatsApp
export const DYNAMIC_CONTENT_LISTS_WHATSAPP = [
	{ options: CONTACT_DATA, title: 'dc_manager.contact_data' },
	{ options: SCENARIO, title: 'dc_manager.scenario' },
	{ options: MESSAGE_INFO_WHATSHAPP, title: 'dc_manager.message_info' },
	{ options: DATA_FIELDS_WHATSAPP, title: 'dc_manager.contact_data_fields' }
];

// ДК для основного блока API-запрос в сценариях
export const DYNAMIC_CONTENT_LISTS_API_QUERY_BLOCK = [
	{ options: CONTACT_DATA, title: 'dc_manager.contact_data' },
	{ options: SCENARIO, title: 'dc_manager.scenario' },
	{
		options: CONTACT_EXTRA_FIELD_NAME,
		title: 'dc_manager.contact_data_fields'
	},
	{ options: TIME_NOW, title: 'dc_manager.data_and_time' }
];
