<tui-hosted-dropdown [tuiDropdownMinHeight]="1000"
                     tuiDropdownAlign="left"
                     [content]="dropdown"
                     [(open)]="calendarVisible"
                     (openChange)="onClose.emit()">
    <span class="wrapper-input"
          [class.isInvalid]="invalidControl"
          [class.focus]="calendarVisible"
          [class.disabled-input]="disabled"
          [class.size_s]="size === 's'"
          [class.size_l]="size === 'l'"
          (click)="calendarVisible=true">
        <input enInput
               tuiAutoFocus
               class="input-date-time h32"
               [autoFocus]="autofocus"
               [placeholder]="placeholder"
               [class.size_s]="size === 's'"
               [class.size_l]="size === 'l'"
               [value]="date"
               [imask]="imask"
               [attr.disabled]="disableInput"
               (focusout)="focusout()">
        <i *ngIf="showReset && !disabled"
           class="cancel-icon en en-cancel-fill"
           (click)="resetCalendar()"></i>
        <i class="calendar-icon en en-date"></i>
    </span>
</tui-hosted-dropdown>

<ng-template #dropdown
             let-activeZone>
    <en-calendar-new *ngIf="!disabled"
                     [tuiActiveZoneParent]="activeZone"
                     [showAdjacent]="true"
                     [min]="min"
                     [max]="max"
                     [type]="type"
                     (closeCalendar)="calendarVisible = false"
                     (onSelect)="onSelect()"></en-calendar-new>
</ng-template>