import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TuiWrapperModule } from '@taiga-ui/core';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';

import { DynamicContentModule } from '@enSend/_shared/components';

import { EnButtonModule, EnTooltipModule } from 'ui-lib';

import { SharedModule } from '@shared';
import { EnCodeAreaComponent } from './code-area.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		TuiWrapperModule,
		EnButtonModule,
		CodemirrorModule,
		DynamicContentModule,
		EnTooltipModule
	],
	declarations: [EnCodeAreaComponent],
	exports: [EnCodeAreaComponent]
})
export class EnCodeAreaModule {}
