import {
	Inject,
	Injectable,
	InjectionToken,
	Optional,
	inject
} from '@angular/core';
import { Sort } from '@enkod-core/interfaces';
import { UrlSaverService, UrlParams } from '@enkod-core/services';
import { WINDOW } from '@enkod-core/utils';
import { BehaviorSubject } from 'rxjs';

export const PAGINATION_SERVICE_CONFIG = new InjectionToken<Sort>(
	'PAGINATION_SERVICE_CONFIG'
);

@Injectable()
export class PaginationService {
	private _sort: Sort = { field: 'id', order: -1 };
	private _resetMode: 'auto' | 'manual' = 'auto';

	get resetMode() {
		return this._resetMode;
	}

	constructor(
		private urlService?: UrlSaverService,
		@Optional()
		@Inject(PAGINATION_SERVICE_CONFIG)
		private readonly config?: {
			sort?: Sort;
			resetMode?: 'auto' | 'manual';
		}
	) {
		if (config?.sort) {
			this._sort = config.sort;
			this.sort$.next(config.sort);
		}
		this.setSavedParamsFromUrl(this.urlService?.getQueryParams());

		if (config?.resetMode) this._resetMode = config.resetMode;
	}

	private search$: BehaviorSubject<string> = new BehaviorSubject('');

	private sort$: BehaviorSubject<Sort> = new BehaviorSubject(this._sort);

	private perPage$ = new BehaviorSubject(10);

	private filter$ = new BehaviorSubject({});

	readonly window = inject(WINDOW);

	get selectSearchValue() {
		return this.search$.asObservable();
	}

	get searchValue(): string {
		return this.search$.value;
	}

	get sortValue(): Sort {
		return this.sort$.value;
	}

	get filterValue() {
		return this.filter$.value;
	}

	get selectSortValue() {
		return this.sort$.asObservable();
	}

	get selectPerPageValue() {
		return this.perPage$.asObservable();
	}

	get selectFilterValue() {
		return this.filter$.asObservable();
	}

	setSearchValue(value: string) {
		this.search$.next(value);
	}

	setSortValue({ field, order }: { field: string; order: number }) {
		this.sort$.next({ field, order });
	}

	setPerPageValue(perPage: number) {
		this.perPage$.next(perPage);
	}

	setFilterValue(filter: any) {
		this.filter$.next(filter);
	}

	setSavedParamsFromUrl(params?: UrlParams) {
		if (params) {
			const perPage = this.window.localStorage.getItem('perPage') || 10;
			this.perPage$.next(Number(perPage) || 10);
			const { sortKey, sortOrder, search } = params;
			this.sort$.next({
				field: sortKey || 'id',
				order: Number(sortOrder) || -1
			});
			this.search$.next(search || '');
		}
	}

	reset() {
		this.search$.next('');
		this.sort$.next({ ...this._sort });
		this.filter$.next({});
	}
}
