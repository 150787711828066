<en-dialog class='dialog'
           appendTo="body"
           [header]="'extra_field_list.edit_field_header' | translate"
           [(visible)]="visible"
           [resizable]="false"
           [modal]="true"
           (onHide)="onHide.emit()">
    <form class="dialog-form"
          [formGroup]="form"
          tabindex="0">
        <div class="en-field">
            <label for="name">{{'extra_field_list.dialog_name' | translate}}</label>
            <input enInput
                   enInputTrim
                   id="name"
                   type="text"
                   formControlName="name" />
            <en-error formControlName="name"></en-error>
            <en-small [visible]="!nameHasError"
                      severity="info"
                      [detail]="'extra_field_list.message_edit_name' | translate">
            </en-small>
        </div>

        <div class="en-field">
            <label for="serviceName">{{'extra_field_list.dialog_service_name' | translate}}</label>
            <input enInput
                   id="serviceName"
                   type="text"
                   [attr.disabled]="true"
                   formControlName="serviceName" />
            <en-error formControlName="serviceName"></en-error>
        </div>

        <div class="en-field">
            <label>{{'extra_field_list.dialog_data_type' | translate}}</label>
            <en-dropdown [options]="dataTypeOptions"
                         [disabled]="true"
                         appendTo="body"
                         formControlName="dataType">
                <ng-template let-item
                             pTemplate="selectedItem">
                    {{item.label | translate}}
                </ng-template>
            </en-dropdown>
        </div>

        <div class="en-field">
            <label for="description">{{'extra_field_list.dialog_description' | translate}}</label>

            <en-text-area nativeId="description"
                          [expandable]="true"
                          formControlName="description"></en-text-area>
        </div>
    </form>
    <p-footer>
        <button type="button"
                style="margin-right: 1rem;"
                enButton
                class="h36 en-button-secondary"
                [label]="'extra_field_list.dialog_button_cancel' | translate"
                (click)="onHide.emit()">
        </button>
        <button type="button"
                enButton
                class="h36"
                [label]="'extra_field_list.dialog_button_save' | translate"
                [enLoading]="loading$ | async"
                (click)="submit()"></button>
    </p-footer>
</en-dialog>