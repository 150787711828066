import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { ContactEntity } from './whatsapp-dc-preview.models';
import {
	WhatsappDcPreviewState,
	WhatsappDcPreviewStore
} from './whatsapp-dc-preview.store';

@Injectable({
	providedIn: 'root'
})
export class WhatsappDcPreviewQuery extends QueryEntity<
	WhatsappDcPreviewState,
	ContactEntity
> {
	constructor(protected store: WhatsappDcPreviewStore) {
		super(store);
	}

	contactsHistory$ = this.selectAll();
}
