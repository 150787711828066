<tui-wrapper class="outline"
             [class.line-2-color]="!useOutlineColor"
             [focused]="focused && useOutlineColor">
</tui-wrapper>

<button enButton
        [enTooltip]="'dc_manager.button_open' | translate"
        type="button"
        class="en-button-lowercase h32 tools__icon content__dc dc-button"
        icon="en en-dinamic-code"
        tooltipPosition="bottom"
        (click)="dcVisible = true"></button>

<div class="content">
        <ngx-codemirror #codemirror
                        [options]="options"
                        (focusChange)="onFocused()"
                        [formControl]="control"></ngx-codemirror>

</div>

<en-dynamic-content *ngIf="dcVisible"
                    location="mail"
                    [openDynamicContent]="dcVisible"
                    (onHide)="dcVisible = false"
                    [hasPosition]="hasPosition"
                    (selectedContent)="updateHTML($event)"></en-dynamic-content>