import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { MessagesQuery } from '@enSend/message/_states/_state-message';

export const MESSAGES_PAGINATOR = new InjectionToken('MESSAGES_PAGINATOR', {
	factory: () => {
		const messagesQuery = inject(MessagesQuery);
		return new PaginatorPlugin(messagesQuery).withControls().withRange();
	}
});
