import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, shareReplay, tap } from 'rxjs/operators';
import { PaginationResponse, ID } from '@datorama/akita';

import {
	NotificationsService,
	PollingService,
	TToastDetail,
	UrlSaverService
} from 'app/core/services';
import { MappedToPaginationResponse } from '@enkod-core/types';
import { NotificationStatus, PollingConfig } from 'ui-lib';

import { TemplateData } from '@enSend/message/message-wizard/message-wizard-common/step-content/2-step-editor/email-editor/email-editor.component';
import { MailingGroupsService } from '@state-enKod/mailing-groups';
import { MessagesStore } from './messages.store';
import { MessagesDataService } from './messages-data.service';
import {
	ChangeStatus,
	DeleteData,
	FiltersModel,
	Message,
	MessageType,
	PlainText
} from './message.model';
import {
	TestMail,
	TestSms,
	TestWhatsapp
} from '../../message-wizard/message-wizard-common/models';

@Injectable({ providedIn: 'root' })
export class MessagesService {
	readonly pollingConfig: PollingConfig = {
		usePolling: false,
		pollingPeriod: 7000,
		errorDelayAttempt: 10000,
		errorCountAttempts: 5
	};

	private readonly pollingService = new PollingService(this.pollingConfig);
	private readonly domainErrorMessages = [
		'Failed activate message. Wrong sending domain',
		'inactive sending domain',
		'invalid sending domain'
	];

	readonly loading$ = new BehaviorSubject<boolean>(false);

	constructor(
		private store: MessagesStore,
		private dataService: MessagesDataService,
		private notificationsService: NotificationsService,
		private urlService: UrlSaverService,
		private translate: TranslateService,
		private mailingGroupsService: MailingGroupsService
	) {}

	private allList$: Observable<Message[]> | null = null;

	getAllList(): Observable<Message[]> {
		if (!this.allList$) {
			this.allList$ = this.dataService.list().pipe(
				map(resp => resp.result),
				catchError(e => {
					this.showRequestError();
					return throwError(e);
				}),
				shareReplay(1)
			);
		}

		return this.allList$;
	}

	getList(
		filters: FiltersModel,
		notSaveInUrl: boolean,
		withoutChannels?: string[]
	): Observable<PaginationResponse<Message>> {
		let lastResponse: MappedToPaginationResponse<Message>;
		const [
			page,
			[
				perPage,
				{
					channel,
					sendingTime,
					tags,
					scenarios,
					sendingType,
					status,
					noDraft
				},
				sort,
				search
			]
		] = filters;
		if (!notSaveInUrl)
			if (sendingType?.toString() === 'scenario') {
				this.urlService.setParamsToUrl(
					{
						location: 'scenario',
						sortKey: sort?.field,
						sortOrder: sort?.order,
						perPage,
						search
					},
					{
						tags,
						scenarios,
						channel,
						status,
						startDate: (sendingTime && sendingTime[0]) || null,
						endDate: (sendingTime && sendingTime[1]) || null,
						sendingType: null
					}
				);
			} else {
				this.urlService.setParamsToUrl(
					{
						location: 'message',
						sortKey: sort?.field,
						sortOrder: sort?.order,
						perPage,
						search
					},
					{
						tags,
						channel,
						status,
						sendingType,
						startDate: (sendingTime && sendingTime[0]) || null,
						endDate: (sendingTime && sendingTime[1]) || null
					}
				);
			}

		const request = this.dataService
			.list({
				limit: perPage,
				offset: (page - 1) * perPage,
				channel: channel?.toString(),
				startDate: sendingTime && sendingTime[0],
				endDate: sendingTime && sendingTime[1],
				tags: tags?.toString(),
				scenarios: scenarios?.toString(),
				sendingType: sendingType?.toString(),
				sort,
				status: status?.toString(),
				search,
				noDraft,
				withoutChannels
			})
			.pipe(
				map(resp => ({
					perPage: perPage || 10,
					lastPage: 0,
					currentPage: page,
					total: Math.ceil(resp.total / perPage) || 1,
					data: resp.result ? [...resp.result] : []
				})),
				tap(value => {
					/** Сохраняем последний ответ,
					 *  используем его при ошибке запроса из поллинга,
					 *  чтобы не сбрасывались данные таблицы */
					if (value) lastResponse = value;
				}),
				catchError(() => {
					/** Показываем тост об ошибке только при первом запросе */
					if (!this.pollingService.pollingWasInitiated) {
						this.showRequestError();
					}

					/** Здесь, соответсвенно, используем данные из последнего ответа, в котором получали инфу */
					if (
						this.pollingService.pollingWasInitiated &&
						lastResponse.data
					)
						return of({
							perPage: perPage || 10,
							lastPage: 0,
							currentPage: page,
							total: Math.ceil(lastResponse.total / perPage) || 1,
							data: [...lastResponse.data]
						});

					return of({
						perPage: 10,
						lastPage: 0,
						currentPage: 1,
						total: 1,
						data: []
					});
				})
			);

		if (this.pollingConfig.usePolling) {
			return this.pollingService.initPolling(request);
		}

		return request;
	}

	getDetail(id: ID) {
		return this.dataService.getDetail(id);
	}

	getMessageUrls(id: ID, type: MessageType) {
		return this.dataService.getMessageUrls(id, type);
	}

	update(message: any): Observable<any> {
		this.store.setLoading(true);
		return this.dataService.update(message).pipe(
			tap(resp => {
				if (!message.isDraft)
					resp.sendingSettings?.groups?.forEach(group =>
						this.mailingGroupsService.removeFromStore(group)
					);
				this.showToast(resp);
				this.clearAllListCache();
			}),
			catchError(e => {
				const errorMessage = e.error.message;
				switch (true) {
					case this.domainErrorMessages.some(domainError =>
						errorMessage.includes(domainError)
					):
						this.showDomainError();
						break;

					case errorMessage.includes('the group is already in use'):
						this.showDoiError();
						break;

					case errorMessage.includes(`message can't be activated`):
					case errorMessage.includes(`can't allow update message`):
						this.showStatusError();
						break;

					default:
						this.showRequestError();
				}

				return throwError(e);
			}),
			finalize(() => this.store.setLoading(false))
		);
	}

	sendTestMail(data: TestMail): Observable<any> {
		this.loading$.next(true);
		return this.dataService.sendTestMail(data).pipe(
			catchError(e => {
				this.domainErrorMessages.includes(e.error.message)
					? this.showDomainError()
					: this.showRequestError();
				return throwError(e);
			}),
			finalize(() => this.loading$.next(false))
		);
	}

	sendTestSms(data: TestSms) {
		this.loading$.next(true);
		return this.dataService.sendTestSms(data).pipe(
			catchError(e => {
				this.showRequestError();
				return throwError(e);
			}),
			finalize(() => this.loading$.next(false))
		);
	}

	sendTestWhatsapp(data: TestWhatsapp) {
		this.loading$.next(true);
		return this.dataService.sendTestWhatsapp(data).pipe(
			tap(() => {
				this.notificationsService
					.show('whatsapp_message_wizard.send_test_toast', {
						status: NotificationStatus.SUCCESS
					})
					.subscribe();
			}),
			catchError(e => {
				this.showRequestError();
				return throwError(e);
			}),
			finalize(() => this.loading$.next(false))
		);
	}

	changeStatus(data: ChangeStatus): Observable<Message> {
		this.store.setLoading(true);
		return this.dataService.changeStatus(data).pipe(
			tap(resp => {
				this.store.update(resp.id, { status: resp.status });
				this.showToast(resp);
			}),
			catchError(e => {
				this.domainErrorMessages.includes(e.error.message)
					? this.showDomainError()
					: this.showRequestError();
				return throwError(e);
			}),
			finalize(() => this.store.setLoading(false))
		);
	}

	cancelMessage(id: string) {
		return this.dataService.cancelSend(id).pipe(
			tap(() => {
				this.notificationsService
					.show('toast.detail_message_cancel', {
						status: NotificationStatus.SUCCESS
					})
					.subscribe();
				this.clearAllListCache();
			}),
			catchError(e => {
				this.showRequestError();
				return throwError(e);
			})
		);
	}

	deleteMessage({
		id,
		name = this.translate.instant('message_list.confirm_noname')
	}: DeleteData): Observable<void> {
		this.store.setLoading(true);
		return this.dataService.remove(id).pipe(
			tap(() => {
				this.store.remove(id);
				this.notificationsService
					.show('toast.detail_message_delete', {
						label: 'toast.deleted',
						status: NotificationStatus.SUCCESS,
						params: {
							id,
							name
						}
					})
					.subscribe();
				this.clearAllListCache();
			}),
			catchError(e => {
				this.showRequestError();
				return throwError(e);
			}),
			finalize(() => this.store.setLoading(false))
		);
	}

	saveTemplate(title: string, data: TemplateData) {
		return this.dataService.saveTemplate(title, data).pipe(
			tap(() => {
				this.notificationsService
					.show('', {
						label: 'message_wizard_edit_step.template_saved',
						status: NotificationStatus.SUCCESS
					})
					.subscribe();
			}),
			catchError(e => {
				this.showRequestError();
				return throwError(e);
			})
		);
	}

	getTextVersion(html: string): Observable<PlainText> {
		return this.dataService.getTextVersion(html);
	}

	private showToast(entity: Message): void {
		let summary: string;
		let detail: TToastDetail;

		switch (entity.status) {
			case 'draft':
				summary = 'toast.created';
				detail = {
					message: 'toast.detail_message_draft_create',
					params: {
						id: entity.id
					}
				};

				break;

			case 'active':
				summary = 'toast.summary_message_active';
				detail = {
					message: 'toast.detail_message_active',
					params: {
						id: entity.id
					}
				};
				break;

			case 'inactive':
				if (entity.sendingType === 'scenario') {
					summary = 'toast.created';
					detail = {
						message: 'toast.detail_message_create',
						params: {
							id: entity.id,
							name: entity.name
						}
					};
					break;
				}
				summary = 'toast.summary_message_inactive';
				detail = {
					message: 'toast.detail_message_inactive',
					params: {
						id: entity.id
					}
				};

				break;

			default:
				summary = 'toast.created';
				detail = {
					message: 'toast.detail_message_create',
					params: {
						id: entity.id,
						name: entity.name
					}
				};
				break;
		}
		this.notificationsService
			.show(detail.message, {
				label: summary,
				status: NotificationStatus.SUCCESS,
				params: detail?.params
			})
			.subscribe();
	}

	checkDeleted(id: number): Observable<DeleteData[]> {
		return this.dataService.checkDeletedData(id).pipe(
			catchError(e => {
				this.showRequestError();
				return throwError(e);
			})
		);
	}

	logImport(id: ID): Observable<Object> {
		return this.dataService.logImport(id);
	}

	private clearAllListCache(): void {
		this.allList$ = null;
	}

	private showRequestError() {
		this.notificationsService
			.show('toast.detail_request_error', {
				label: 'toast.summary_try_later',
				status: NotificationStatus.ERROR
			})
			.subscribe();
	}

	private showDoiError() {
		this.notificationsService
			.show('toast.detail_doi_exists', {
				label: 'toast.summary_doi_exists',
				status: NotificationStatus.ERROR
			})
			.subscribe();
	}

	private showDomainError() {
		this.notificationsService
			.show('toast.detail_message_wrong_domain', {
				label: 'toast.summary_unable_to_activate',
				status: NotificationStatus.ERROR
			})
			.subscribe();
	}

	private showStatusError() {
		this.notificationsService
			.show('toast.detail_message_wrong_status', {
				label: 'toast.summary_unable_to_activate',
				status: NotificationStatus.ERROR
			})
			.subscribe();
	}

	destroyPolling() {
		this.pollingService.destroyPolling();
	}
}
