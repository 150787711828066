import { MessageType } from '@enSend/message/_states/_state-message';
import { SelectItem } from 'primeng/api';

export const CHANNEL_OPTIONS: SelectItem<MessageType>[] = [
	{
		label: 'channels_name.email',
		value: 'mail'
	},
	{
		label: 'channels_name.push',
		value: 'push'
	},
	{
		label: 'channels_name.sms',
		value: 'sms'
	},
	{
		label: 'channels_name.mobPush',
		value: 'mobPush'
	},
	{
		label: 'channels_name.whatsapp',
		value: 'whatsapp'
	}
];
