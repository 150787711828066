import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AuthQuery } from '@state-auth';

@Injectable()
export class AccessChatbotGuard implements CanActivate, CanLoad {
	constructor(private authQuery: AuthQuery, private router: Router) {}

	canActivate(): boolean {
		const { isAccessChatbot } = this.authQuery;
		if (!isAccessChatbot) {
			this.router.navigateByUrl('ensend/chatbots');
		}
		return isAccessChatbot;
	}

	canLoad(): boolean {
		const { isAccessChatbot } = this.authQuery;
		if (!isAccessChatbot) this.router.navigateByUrl('ensend/chatbots');
		return isAccessChatbot;
	}
}
