import { NgModule } from '@angular/core';

import { SystemTagModule } from '@enkod-core/pipes';

import { ENSEND_SHARED_PIPES } from './pipes';
import { SplitTestVersionPipe } from './pipes/split-test-version.pipe';

@NgModule({
	imports: [SystemTagModule],
	declarations: [...ENSEND_SHARED_PIPES],
	exports: [...ENSEND_SHARED_PIPES, SystemTagModule],
	providers: [SplitTestVersionPipe]
})
export class EnsendSharedModule {}
