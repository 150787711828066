import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ContactEntity } from './whatsapp-dc-preview.models';

export interface WhatsappDcPreviewState extends EntityState<ContactEntity> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'whatsapp-dc-preview'
})
export class WhatsappDcPreviewStore extends EntityStore<WhatsappDcPreviewState> {
	constructor() {
		super({});
	}
}
