<ng-container [ngSwitch]="type">
    <input *ngSwitchCase="'number'"
           enInput
           [id]="inputId"
           [placeholder]="placeholder"
           [class.error]="!isValid"
           [imask]="numberMask"
           [ngClass]="styleClass"
           [formControl]="control"/>

    <input *ngSwitchCase="'float'"
           enInput
           [id]="inputId"
           [placeholder]="placeholder"
           [class.error]="!isValid"
           [imask]="floatMask"
           [ngClass]="styleClass"
           [formControl]="control"/>

    <input *ngSwitchCase="'phone'"
           enInput
           [id]="inputId"
           [placeholder]="placeholder"
           [class.error]="!isValid"
           [styleClass]="isValid ? 'h36' : 'h36 error'"
           [ngClass]="styleClass"
           [imask]="telMask"
           [attr.disabled]="disable ? '' : null"
           [formControl]="control"/>

    <en-dropdown *ngSwitchCase="'bool'"
                 appendTo="body"
                 [forceInvalid]="!isValid"
                 [styleClass]="styleClass"
                 [placeholder]="'en_field_input.bool_placeholder' | translate"
                 [options]="boolDefaultValue"
                 (onShow)="emitIgnoredElement.emit($event.element)"
                 [formControl]="control"></en-dropdown>

    <input *ngSwitchCase="'email'"
           enInput
           [id]="inputId"
           [placeholder]="placeholder"
           [class.error]="!isValid"
           [ngClass]="styleClass"
           [attr.disabled]="disable ? '' : null"
           [formControl]="control"/>

    <div *ngSwitchCase="'date'"
         class="calendar__input">
       <en-dropdown-calendar-new #calendar
                                 [type]="'date'"
                                 [forceInvalid]="!isValid"
                                 [closeAfterSelect]="true"
                                 [formControl]="control"></en-dropdown-calendar-new>
    </div>

    <div *ngSwitchCase="'dateTime'"
         class="calendar__input">
        <en-dropdown-calendar-new #calendar
                                  [forceInvalid]="!isValid"
                                  [closeAfterSelect]="true"
                                  [formControl]="control"></en-dropdown-calendar-new>
    </div>

    <input *ngSwitchCase="'text'"
           enInput
           [id]="inputId"
           [placeholder]="placeholder"
           [class.error]="!isValid"
           [ngClass]="styleClass"
           [formControl]="control"/>

    <input *ngSwitchDefault
           enInput
           [id]="inputId"
           [placeholder]="placeholder"
           [class.error]="!isValid"
           [ngClass]="styleClass"
           [formControl]="control"/>
</ng-container>
