import { StatusType } from '@enSend/message/_states/_state-message';
import { SelectItem } from 'primeng/api';

export const STATUS_OPTIONS: SelectItem<StatusType>[] = [
	{
		label: 'message_list.status_draft',
		value: 'draft'
	},
	{
		label: 'message_list.status_waiting',
		value: 'waiting'
	},
	{
		label: 'message_list.status_sending',
		value: 'sending'
	},
	{
		label: 'message_list.status_testing',
		value: 'testing'
	},
	{ label: 'message_list.status_choosing_winner', value: 'choosingWinner' },
	{
		label: 'message_list.status_sent',
		value: 'sent'
	},
	{
		label: 'message_list.status_finished',
		value: 'finished'
	},
	{
		label: 'message_list.status_active',
		value: 'active'
	},
	{
		label: 'message_list.status_inactive',
		value: 'inactive'
	}
];
