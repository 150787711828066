import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';

import {
	NotificationsService,
	PollingService,
	UrlSaverService
} from 'app/core/services';
import { PaginationResponse } from '@datorama/akita';
import { PaginationResponse as PaginationResp } from 'app/core/types';
import { NotificationStatus, PollingConfig } from 'ui-lib';
import { Integration } from 'app/modules/settings/integrations/_state';

import { WhatsAppTemplatesDataService } from './whatsapp-template-data.service';

import { WhatsAppTemplate } from './whatsapp-template.model';

import { DeleteData, FiltersModel, RelatedMessages } from '../_state-message';
import { WhatsAppTemplatesStore } from './whatsapp-template.store';

@Injectable({ providedIn: 'root' })
export class WhatsAppTemplatesService {
	readonly pollingConfig: PollingConfig = {
		usePolling: false,
		pollingPeriod: 7000,
		errorDelayAttempt: 10000,
		errorCountAttempts: 5
	};

	private readonly pollingService = new PollingService(this.pollingConfig);

	constructor(
		private store: WhatsAppTemplatesStore,
		private dataService: WhatsAppTemplatesDataService,
		private notificationsService: NotificationsService,
		private urlService: UrlSaverService,
		private http: HttpClient
	) {}

	getAllList(
		checkLoad?: boolean
	): Observable<{ result: WhatsAppTemplate[]; total: number }> {
		return this.dataService.getAll(checkLoad);
	}

	getList(
		filters: FiltersModel,
		notSaveInUrl: boolean
	): Observable<PaginationResponse<WhatsAppTemplate>> {
		const [
			page,
			[
				perPage,
				{ channel, sendingTime, status, noDraft, tags },
				sort,
				search
			]
		] = filters;

		if (!notSaveInUrl)
			this.urlService.setParamsToUrl(
				{
					location: 'whatsapp',
					sortKey: sort?.field,
					sortOrder: sort?.order,
					perPage,
					search
				},
				{
					channel,
					status,
					tags,
					startDate: (sendingTime && sendingTime[0]) || null,
					endDate: (sendingTime && sendingTime[1]) || null,
					sendingType: null
				}
			);

		const request = this.dataService
			.list({
				limit: perPage,
				offset: (page - 1) * perPage,
				channel: channel?.toString(),
				startDate: sendingTime && sendingTime[0],
				endDate: sendingTime && sendingTime[1],
				sort:
					sort.field === 'sendingTime'
						? {
								field: 'id',
								order: -1
						  }
						: sort,
				statuses: status?.toString(),
				tags: tags?.toString(),
				search,
				noDraft
			})
			.pipe(
				switchMap((resp: any) => {
					return of({
						perPage: perPage || 10,
						lastPage: 0,
						currentPage: page,
						total: Math.ceil(resp.total / perPage) || 1,
						data: resp.result ? [...resp.result] : []
					});
				}),
				catchError(() => {
					this.notificationsService
						.show('toast.detail_request_error', {
							label: 'toast.summary_try_later',
							status: NotificationStatus.ERROR
						})
						.subscribe();
					return of({
						perPage: 10,
						lastPage: 0,
						currentPage: 1,
						total: 0,
						data: []
					});
				})
			);

		if (this.pollingConfig.usePolling) {
			return this.pollingService.initPolling(request);
		}

		return request;
	}

	deleteTemplate({
		id,
		name = 'message_list.confirm_noname'
	}: DeleteData): Observable<Object> {
		this.store.setLoading(true);
		return this.dataService.delete(id).pipe(
			tap(() => {
				this.store.remove(id);
				this.notificationsService
					.show('toast.detail_whatsapp_template_delete', {
						label: 'toast.deleted',
						status: NotificationStatus.SUCCESS,
						params: {
							id,
							name
						}
					})
					.subscribe();
			}),
			catchError(e => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(e);
			}),
			finalize(() => this.store.setLoading(false))
		);
	}

	checkTemplate(deleteData: DeleteData): Observable<RelatedMessages> {
		return this.dataService.checkActive(deleteData.id).pipe(
			map((resp: RelatedMessages) => {
				return {
					isRelated: resp.isRelated,
					messages: resp.messages
				};
			})
		);
	}

	getWhatsappIntegration(): Observable<PaginationResp<Integration>> {
		return this.http.get<PaginationResp<Integration>>(
			'enkod/whatsapp/integration/'
		);
	}
}
