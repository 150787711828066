import { Component, Injectable } from '@angular/core';
import { UntypedFormGroup, FormGroup } from '@angular/forms';
import { CanDeactivate } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';

interface GuardedComponent extends Component {
	form: UntypedFormGroup | FormGroup;
}

@Injectable()
export class FormTouchedGuard implements CanDeactivate<GuardedComponent> {
	confirm$: Subject<boolean>;

	constructor(private confirmationService: ConfirmationService) {}

	canDeactivate(component: GuardedComponent) {
		if (component.form.untouched) return true;
		this.confirm$ = new Subject<boolean>();
		this.confirmationService.confirm({
			key: 'confirmLeave',

			accept: () => this.confirm$.next(true),
			reject: () => this.confirm$.next(false)
		});
		return this.confirm$;
	}
}
