import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WaTemplateParamsDTO } from '@enSend/message/whatsapp-template-wizard/models';
import { WhatsappDcTemplate } from './whatsapp-dc-preview.models';

@Injectable()
export class WhatsappPreviewDataService {
	constructor(private http: HttpClient) {}

	generatePreview(
		data: WhatsappDcTemplate
	): Observable<{ params: WaTemplateParamsDTO }> {
		return this.http.post<{ params: WaTemplateParamsDTO }>(
			'dc/whatsapp/preview',
			data
		);
	}
}
