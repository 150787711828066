import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { RestService } from 'app/core/utils/rest-service';
import { RelatedDataItem } from 'ui-lib';

import { MailingGroup } from './mailing-group.model';

@Injectable({
	providedIn: 'root'
})
export class MailingGroupsDataService extends RestService<MailingGroup> {
	constructor(protected http: HttpClient) {
		super(http, 'enkod/subscription');
	}

	checkSystemNameMailing(
		systemName: String
	): Observable<{ uniqueSystemName: boolean }> {
		return this.http.post('enkod/subscription/check', {
			systemName
		}) as Observable<{ uniqueSystemName: boolean }>;
	}

	checkRelatesMessages(id: number) {
		return this.http.get<RelatedDataItem[]>(
			`enkod/subscription/check/${id}`
		);
	}
}
