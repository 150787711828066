import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ALL_LIST_STORE_NAME } from '@enkod-core/constants';
import { MailingGroup } from './mailing-group.model';

export interface MailingGroupsState extends EntityState<MailingGroup> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'mailing-groups'
})
export class MailingGroupsStore extends EntityStore<MailingGroupsState> {
	constructor() {
		super({
			[ALL_LIST_STORE_NAME]: null,
			allDoiList: null
		});
	}
}
