import { WaTemplateParamsDTO } from '@enSend/message/whatsapp-template-wizard/models';
import { WhatsappPreviewParams } from '@enSend/scenario/scenario-wizard/scenario-canva/inspector/send-message/models';

export class WhatsappParamsOTD {
	private params: WhatsappPreviewParams;

	constructor(value: WaTemplateParamsDTO) {
		const convertedParams: WhatsappPreviewParams = {};

		Object.entries(value).forEach(
			(param: Array<string | string[] | string[][]>) => {
				if (param?.length) {
					if (
						param[0] === 'body_text' ||
						param[0] === 'footer_text'
					) {
						const [text] = param[1] as string[][];
						convertedParams[param[0]] = text;
					} else if (
						param[0] === 'url_button_params' ||
						param[0] === 'header_text'
					) {
						convertedParams[param[0]] = param[1] as string[];
					} else if (typeof param[0] === 'string') {
						convertedParams.header_handle = param[1] as string[];
					}
				}
			}
		);

		Object.assign(this, { params: convertedParams });
	}

	getValue(): WhatsappPreviewParams {
		return this.params;
	}
}
