import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import {
	WhatsAppTemplatesState,
	WhatsAppTemplatesStore
} from './whatsapp-template.store';

@Injectable({ providedIn: 'root' })
export class WhatsAppTemplatesQuery extends QueryEntity<WhatsAppTemplatesState> {
	constructor(protected store: WhatsAppTemplatesStore) {
		super(store);
	}
}
