import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

import { VALUE } from '@enkod-core/tokens';
import { NotificationsService } from '@enkod-core/services';
import { NotificationStatus } from 'ui-lib';
import { DCListsProvider, DCLocation, OPTIONS_PROVIDER } from './constants';

@UntilDestroy()
@Component({
	selector: 'en-dynamic-content',
	templateUrl: './dynamic-content.component.html',
	styleUrls: ['./dynamic-content.component.scss'],
	providers: [OPTIONS_PROVIDER],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicContentComponent implements OnInit {
	searched$ = new BehaviorSubject(false);
	searchControl = new UntypedFormControl('');
	searchedOptions: SelectItem[] = [];

	@Input() location: DCLocation;
	@Input() openDynamicContent: boolean;
	@Input() hasPosition: boolean;

	@Output() onHide = new EventEmitter();
	@Output() selectedContent = new EventEmitter();

	constructor(
		@Inject(VALUE)
		private readonly dynamicContentLists: DCListsProvider,
		private notification: NotificationsService,
		private translate: TranslateService
	) {}

	get DCLists() {
		return this.dynamicContentLists[this.location];
	}

	ngOnInit(): void {
		this.searchListener();
	}

	private searchListener() {
		this.searchControl.valueChanges
			.pipe(
				untilDestroyed(this),
				debounceTime(200),
				tap(val => {
					this.searchedOptions = [];

					if (val) this.searched$.next(true);
					else this.searched$.next(false);

					this.DCLists.forEach(list => {
						list.options.forEach(option => {
							const translatedLabel = this.translate.instant(
								option.label
							);

							if (
								translatedLabel
									.toLowerCase()
									.includes(val.toLowerCase())
							) {
								this.searchedOptions.push(option);
							}
						});
					});
				})
			)
			.subscribe();
	}

	selectContent(value: string) {
		if (this.hasPosition) {
			this.selectedContent.emit(value);
			this.onHide.emit();
		} else {
			this.notification
				.show('', {
					label: 'dc_manager.error_no_position',
					status: NotificationStatus.ERROR
				})
				.pipe(untilDestroyed(this))
				.subscribe();
		}
	}
}
