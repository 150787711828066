import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import {
	EnTableModule,
	EnDropdownModule,
	EnInputModule,
	EnButtonModule,
	EnTooltipModule,
	EnDropdownCalendarNewModule
} from 'ui-lib';
import { IMaskModule } from 'angular-imask';
import { TranslateModule } from '@ngx-translate/core';
import { FieldInputComponent } from './field-input.component';

@NgModule({
	declarations: [FieldInputComponent],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		EnTableModule,
		EnDropdownModule,
		EnInputModule,
		CheckboxModule,
		EnButtonModule,
		InputNumberModule,
		EnTooltipModule,
		TranslateModule,
		EnDropdownCalendarNewModule,
		IMaskModule
	],
	exports: [FieldInputComponent]
})
export class FieldInputModule {}
