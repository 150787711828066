import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TuiLetModule } from '@taiga-ui/cdk';

import { SharedModule } from '@shared';
import { TuiScrollbarModule } from '@enkod-core/components';
import { EnButtonModule, EnDialogModule, SearchModule } from 'ui-lib';
import { DynamicContentComponent } from './dynamic-content.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		SharedModule,
		EnButtonModule,
		EnDialogModule,
		TuiScrollbarModule,
		TuiLetModule,
		SearchModule
	],
	declarations: [DynamicContentComponent],
	exports: [DynamicContentComponent]
})
export class DynamicContentModule {}
