import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Optional,
	Output,
	Self
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

import IMask from 'imask';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	DATE_FILLER_LENGTH,
	TuiDay,
	TuiDayRange,
	tuiPure,
	TuiTime
} from '@taiga-ui/cdk';
import { debounceTime } from 'rxjs/operators';

import {
	createImaskDate,
	createImaskDateTime
} from 'ui-lib/calendar-range-new/mask/calendar-time-mask';
import { CalendarService } from 'app/ui-lib/new-calendar/calendar.service';

import {
	LENGTH_DATE_RANGE,
	LENGTH_DATE_TIME_RANGE,
	LENGTH_DATE_TIME,
	yearMaxFromCurrent,
	yearMin
} from '../calendar-range-new/calendar-range.model';

@UntilDestroy()
@Component({
	selector: 'en-dropdown-calendar-range',
	templateUrl: './dropdown-calendar-range.component.html',
	styleUrls: ['./dropdown-calendar-range.component.scss'],
	providers: [CalendarService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownCalendarRangeComponent implements OnInit {
	calendarVisible = false;
	showClearIcon = false;
	fromInput = false;
	date: string;
	value: TuiDayRange | null = null;
	imask: IMask.MaskedPattern<string>;
	firstLoad = true;

	noEmitFirstPatch = false;

	@Input() min = new TuiDay(yearMin, 0, 1);
	@Input() max = new TuiDay(
		TuiDay.currentLocal().year + yearMaxFromCurrent,
		11,
		31
	);

	@Input() disabled = false;
	@Input() period: string;
	@Input() type = 'dateTime';
	@Input() size = 'm';
	@Input() placeholder = 'common.select';
	@Input() showReset = true;
	@Input() isInvalid = false;
	@Input() noEmitEventMode = false;
	@Input() readonly = false;
	@Input()
	set loadedFromUrlDate(value: [number, number]) {
		if (value[0]) {
			this.noEmitFirstPatch = true;
		}
	}

	@Output() dateRangeStringEvent = new EventEmitter();
	@Output() firstDateRangeStringEvent = new EventEmitter();

	constructor(
		@Optional()
		@Self()
		@Inject(NgControl)
		private ngControl: NgControl | null,
		private calendarService: CalendarService,
		private cd: ChangeDetectorRef
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
		this.calendarService.changeFormat('range');
	}

	get dropdownLabel() {
		return this.showClearIcon
			? 'date-range-calendar.during_the_period'
			: 'date-range-calendar.for_all_the_time';
	}

	get control(): AbstractControl | null {
		if (this.ngControl) {
			return this.ngControl.control;
		}
		return null;
	}

	get invalidControl() {
		return (
			(this.control?.invalid &&
				this.control?.untouched &&
				this.control?.dirty) ||
			this.isInvalid
		);
	}

	get dateRange() {
		return this.calendarService.getDateRange();
	}

	get minDate() {
		return this.calendarService.getMinDate();
	}

	get maxDate() {
		return this.calendarService.getMaxDate();
	}

	get disableInput() {
		return this.disabled ? true : null;
	}

	@tuiPure
	get imaskOptions(): IMask.MaskedPattern<string> {
		return this.type === 'dateTime'
			? createImaskDateTime(this.min, this.max)
			: createImaskDate(this.min, this.max);
	}

	ngOnInit() {
		this.imask = this.imaskOptions;

		this.calendarService.changeMinMaxValue(this.min, this.max);
		this.calendarService.changePeriod(this.period);
		this.calendarService.changeType(this.type);
		if (
			this.minDate >
			TuiDay.normalizeParse(
				this.calendarService.getStringDate(
					this.control?.value?.startDate
				)
			)
		)
			this.control?.setErrors({ incorrect: true });
		this.changeDate();

		this.patchDateFromParams(
			this.control?.value?.startDate,
			this.control?.value?.endDate
		);
	}

	onTouch: Function = () => {};
	writeValue(): void {}

	registerOnChange(): void {
		if (!!this.control?.value?.startDate && this.type === 'date') {
			this.calendarService.changeInputFirstDate(
				this.calendarService.getStringDate(
					this.control?.value?.startDate
				)
			);
			this.calendarService.changeInputDoubleDate(
				this.calendarService.getStringDate(this.control?.value?.endDate)
			);
			this.date = this.calendarService.getDateRangeString();
			this.dateRangeStringEvent.emit(this.date);
			this.control.markAsDirty();
		} else if (
			!!this.control?.value?.startDate &&
			this.type === 'dateTime'
		) {
			this.calendarService.changeInputFirstDate(
				this.calendarService.getStringDate(
					this.control?.value?.startDate
				)
			);
			this.calendarService.changeInputFirstTime(
				this.calendarService.getStringTime(
					this.control?.value?.startDate
				)
			);
			this.calendarService.changeInputDoubleDate(
				this.calendarService.getStringDate(this.control?.value?.endDate)
			);
			this.calendarService.changeInputDoubleTime(
				this.calendarService.getStringTime(this.control?.value?.endDate)
			);
			this.date = this.calendarService.getDateTimeRangeString();
			this.control.markAsDirty();
		}
	}

	registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}

	private changeDate() {
		this.fromInput = false;
		this.calendarService.currentDateRange
			.pipe(untilDestroyed(this), debounceTime(400))
			.subscribe(value => {
				const controlHasValue =
					this.fromInput &&
					this.noEmitFirstPatch &&
					this.type !== 'date';

				this.control?.patchValue(
					{
						startDate: this.calendarService.getNumberDate(
							value[0].date,
							value[0].time,
							!!controlHasValue
						),
						endDate: this.calendarService.getNumberDate(
							value[1].date,
							value[1].time,
							!!controlHasValue
						)
					},
					{
						emitEvent: this.noEmitEventMode
							? !this.noEmitFirstPatch
							: true
					}
				);
				this.noEmitFirstPatch = false;

				this.date =
					this.type === 'dateTime'
						? this.calendarService.getDateTimeRangeString()
						: this.calendarService.getDateRangeString();

				this.imask.value = this.date;

				this.dateRangeStringEvent.emit(this.date);
				if (this.firstLoad) {
					this.firstDateRangeStringEvent.emit(this.date);
					this.firstLoad = false;
				}
				this.cd.markForCheck();
			});
	}

	preventInput(event: KeyboardEvent | ClipboardEvent) {
		if (this.readonly) event.preventDefault();
	}

	focusout() {
		this.date = this.imask.value;

		switch (true) {
			case this.date.substring(0, 10) === '00.00.0000' &&
				this.date.substring(20, 30) === '00.00.0000':
				this.resetCalendar();
				break;

			case this.type === 'date' &&
				this.date.length === DATE_FILLER_LENGTH:
				this.calendarService.changeInputFirstDate(
					this.date.substring(0, 10)
				);
				this.control?.patchValue({
					startDate: this.calendarService.getNumberDate(
						this.dateRange[0].date,
						new TuiTime(0, 0)
					),
					endDate: null
				});
				this.control?.markAsDirty();
				break;

			case this.type === 'dateTime' &&
				this.date.length === LENGTH_DATE_TIME:
				this.calendarService.changeInputFirstDate(
					this.date.substring(0, 10)
				);
				this.calendarService.changeInputFirstTime(
					this.date.substring(12, 17)
				);
				this.control?.patchValue({
					startDate: this.calendarService.getNumberDate(
						this.dateRange[0].date,
						this.dateRange[0].time
					),
					endDate: null
				});
				this.control?.markAsDirty();
				break;

			case this.type === 'date' && this.date.length === LENGTH_DATE_RANGE:
				this.calendarService.changeInputFirstDate(
					this.date.substring(0, 10)
				);
				this.calendarService.changeInputDoubleDate(
					this.date.substring(13, 23)
				);
				this.control?.patchValue({
					startDate: this.calendarService.getNumberDate(
						this.dateRange[0].date,
						new TuiTime(0, 0)
					),
					endDate: this.calendarService.getNumberDate(
						this.dateRange[1].date,
						new TuiTime(0, 0)
					)
				});
				this.control?.markAsDirty();
				break;

			case this.type === 'dateTime' &&
				this.date.length === LENGTH_DATE_TIME_RANGE:
				this.calendarService.changeInputFirstDate(
					this.date.substring(0, 10)
				);
				this.calendarService.changeInputFirstTime(
					this.date.substring(12, 17)
				);
				this.calendarService.changeInputDoubleDate(
					this.date.substring(20, 30)
				);
				this.calendarService.changeInputDoubleTime(
					this.date.substring(32, 37)
				);
				this.control?.patchValue({
					startDate: this.calendarService.getNumberDate(
						this.dateRange[0].date,
						this.dateRange[0].time
					),
					endDate: this.calendarService.getNumberDate(
						this.dateRange[1].date,
						this.dateRange[1].time
					)
				});
				break;

			default:
				this.control?.patchValue({ startDate: null, endDate: null });
		}
	}

	patchDateFromParams(start: number, end: number) {
		this.fromInput = false;
		if (
			!!start &&
			!end &&
			(this.type === 'dateTime' || this.type === 'date')
		) {
			this.calendarService.changeInputFirstDate(
				this.calendarService.getStringDate(start)
			);
			this.calendarService.changeInputFirstTime(
				this.calendarService.getStringTime(start)
			);
		} else if (
			!!end &&
			(this.type === 'dateTime' || this.type === 'date')
		) {
			this.calendarService.patchDate(start, end);
		}
	}

	setLast30Days() {
		this.calendarService.changePeriodDateRange({ day: -29 });
	}

	setDateString(value: string) {
		this.date = value;
		this.cd.markForCheck();
	}

	resetCalendar() {
		this.fromInput = false;
		this.date = '';
		this.control?.patchValue({ startDate: null, endDate: null });
		this.calendarService.resetDateRange();
		this.cd.markForCheck();
	}
}
