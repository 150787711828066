import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	Self
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

import IMask from 'imask';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { TuiDay, TuiMonth, tuiPure, TuiTime } from '@taiga-ui/cdk';

import { CalendarService } from 'app/ui-lib/new-calendar/calendar.service';
import { yearMaxFromCurrent, yearMin } from '../calendarNew/calendar.model';
import {
	createImaskDate,
	createImaskDateTime
} from '../calendarNew/mask/calendar-time-mask';

@UntilDestroy()
@Component({
	selector: 'en-dropdown-calendar-new',
	templateUrl: './dropdown-calendar.component.html',
	styleUrls: ['./dropdown-calendar.component.scss'],
	providers: [CalendarService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownCalendarComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	calendarVisible = false;
	selected = false;
	date: string;
	currentDate: TuiDay;
	imask: IMask.MaskedPattern<string>;

	@Input() autofocus = false;
	@Input() disabled = false;
	@Input() period = 'all';
	@Input() placeholder = this.translate.instant('common.select');
	@Input() min = new TuiDay(yearMin, 0, 1);
	@Input() max = new TuiDay(
		TuiDay.currentLocal().year + yearMaxFromCurrent,
		11,
		31
	);

	@Input() forceInvalid = false;
	@Input() type = 'dateTime';
	@Input() size = 'm';
	@Input() showReset = true;
	@Input() forceMarkForCheck$: Subject<void>;
	@Input() reset$: Subject<void>;
	@Input() set reset(value: boolean) {
		if (value) this.resetCalendar();
	}

	@Input() focusoutEnable = true;
	@Input() closeAfterSelect = false;

	@Output() onClose = new EventEmitter();

	constructor(
		@Optional()
		@Self()
		@Inject(NgControl)
		private ngControl: NgControl | null,
		@Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
		private calendarService: CalendarService,
		private translate: TranslateService
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
		this.calendarService.changeFormat('single');
	}

	get control(): AbstractControl | null {
		if (this.ngControl) {
			return this.ngControl.control;
		}
		return null;
	}

	get invalidControl() {
		return (
			(this.control?.invalid &&
				this.control?.untouched &&
				this.control?.dirty) ||
			this.forceInvalid
		);
	}

	get minDate() {
		return this.calendarService.getMinDate();
	}

	get maxDate() {
		return this.calendarService.getMaxDate();
	}

	get disableInput() {
		return this.disabled ? true : null;
	}

	@tuiPure
	get imaskOptions(): IMask.MaskedPattern<string> {
		return this.type === 'dateTime'
			? createImaskDateTime(this.minDate, this.maxDate)
			: createImaskDate(this.minDate, this.maxDate);
	}

	ngOnInit(): void {
		this.calendarService.changeMinMaxValue(this.min, this.max);
		this.calendarService.changePeriod(this.period);
		this.calendarService.changeType(this.type);
		this.changeDate();
		this.registerOnChange();

		this.imask = this.imaskOptions;

		this.reset$
			?.pipe(untilDestroyed(this))
			.subscribe(() => this.resetCalendar());

		this.forceMarkForCheck$
			?.pipe(untilDestroyed(this))
			.subscribe(() => this.markForCheck());
	}

	ngAfterViewInit(): void {
		this.registerOnChange();
	}

	registerOnChange() {
		if (this.control?.value) {
			this.calendarService.changeDateTime(
				this.calendarService.getStringDateTime(
					this.control.value,
					this.type === 'dateTime'
				)
			);
			const dateMonth = TuiDay.normalizeParse(
				this.calendarService.getStringDate(this.control.value)
			);
			this.calendarService.changeShowDate(
				new TuiMonth(dateMonth.year, dateMonth.month)
			);
			if (this.type === 'dateTime') {
				this.date = this.calendarService.getDateTime(
					this.control?.value
				);
				this.control.markAsDirty();
			} else {
				this.date = this.calendarService.getDateTime(
					this.control?.value
				);
				this.control.markAsDirty();
			}
		}
	}

	private markForCheck() {
		this.changeDetectorRef.markForCheck();
	}

	writeValue(): void {}

	onTouch: Function = () => {};

	registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}

	focusout() {
		if (this.focusoutEnable) this.setDate();
	}

	setDate() {
		this.date = this.imask.value;

		switch (true) {
			case this.type === 'date' && this.date.length === 10:
				this.calendarService.changeCurrentDayFromInput(
					TuiDay.normalizeParse(this.date.substring(0, 10))
				);
				this.control?.patchValue(
					this.calendarService.getNumberDate(
						TuiDay.normalizeParse(this.date.substring(0, 10)),
						new TuiTime(0, 0)
					)
				);
				this.control?.markAsDirty();
				break;

			case this.type === 'dateTime' && this.date.length === 17:
				this.calendarService.changeCurrentDayFromInput(
					TuiDay.normalizeParse(this.date.substring(0, 10))
				);
				this.control?.patchValue(
					this.calendarService.getNumberDate(
						TuiDay.normalizeParse(this.date.substring(0, 10)),
						TuiTime.fromString(this.date.substring(12, 17))
					)
				);
				this.control?.markAsDirty();
				break;

			default:
				this.control?.patchValue(null);
		}
	}

	onSelect() {
		this.selected = true;
		if (this.closeAfterSelect) this.calendarVisible = false;
	}

	private changeDate() {
		this.calendarService.currentDate
			.pipe(untilDestroyed(this))
			.subscribe(value => {
				if (this.type === 'dateTime') {
					this.control?.patchValue(
						this.calendarService.getNumberDate(
							value.date,
							value.time,
							true
						)
					);
					this.date = this.calendarService.getDateTime(
						this.control?.value
					);
					if (this.date.length === 17) {
						this.control?.markAsDirty();
					}
				} else {
					this.control?.patchValue(
						this.calendarService.getNumberDate(
							value.date,
							new TuiTime(0, 0)
						)
					);

					this.date = this.calendarService.getDateString(
						this.control?.value
					);
					if (this.date.length === 10) {
						this.control?.markAsDirty();
					}
				}

				if (this.imask) this.imask.value = this.date;
			});
	}

	resetCalendar() {
		this.control?.patchValue(null);
		this.date = '';
		this.calendarService.resetDate();
		this.calendarService.resetDateRange();
		this.markForCheck();
	}

	ngOnDestroy() {
		this.focusout();
	}
}
