import { StatusTemplateType } from '@enSend/message/_states/_state-whatsapp-template';
import { SelectItem } from 'primeng/api';

export const STATUS_WHATSAPP_OPTIONS: SelectItem<StatusTemplateType>[] = [
	{
		label: 'message_list.status_approved',
		value: 'approved'
	},
	{
		label: 'message_list.status_rejected',
		value: 'rejected'
	},
	{
		label: 'message_list.status_pending',
		value: 'pending'
	},
	{
		label: 'message_list.status_draft',
		value: 'draft'
	}
];
