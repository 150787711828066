import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { Observable } from 'rxjs';

import { MessagesStore, MessagesState } from './messages.store';
import { Message } from './message.model';

@Injectable({ providedIn: 'root' })
export class MessagesQuery extends QueryEntity<MessagesState> {
	constructor(protected store: MessagesStore) {
		super(store);
	}

	allMessages$ = this.select('allMessages') as Observable<Message[]>;
}
