import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Injectable()
export class WhatsappDcFormService {
	private readonly _form = this.fb.group({
		id: 0,
		text: '',
		footer: '',
		titleType: 'none',
		title: this.fb.group({ type: '', format: '', text: '' }),
		params: this.fb.group({
			body_text: this.fb.array([]),
			footer_text: this.fb.array([]),
			header_handle: this.fb.array([]),
			header_text: this.fb.array([]),
			url_button_params: this.fb.array([])
		}),
		buttons: this.fb.array([])
	});

	constructor(private fb: UntypedFormBuilder) {}

	get form(): UntypedFormGroup {
		return this._form;
	}
}
