export interface WhatsAppTemplate {
	id: number;
	type: TemplateType;
	name?: string;
	status: StatusTemplateType;
}

export interface TemplateItemOption {
	label: string;
	value: number;
	type: TemplateType;
}

export type StatusTemplateType = 'approved' | 'rejected' | 'pending' | 'draft';

export type TemplateType = 'media' | 'text';
