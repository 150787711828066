import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { WhatsappDcPreviewStore } from './whatsapp-dc-preview.store';
import { WhatsappPreviewDataService } from './whatsapp-dc-preview-data.service';
import {
	ContactEntity,
	WhatsappDcTemplate
} from './whatsapp-dc-preview.models';

@Injectable()
export class WhatsappPreviewService {
	readonly loading$ = new BehaviorSubject<boolean>(false);

	constructor(
		private whatsappDcPreviewStore: WhatsappDcPreviewStore,
		private dataService: WhatsappPreviewDataService
	) {
		this.whatsappDcPreviewStore.setLoading(false);
	}

	generatePreview(data: WhatsappDcTemplate) {
		this.loading$.next(true);
		return this.dataService.generatePreview(data).pipe(
			finalize(() => {
				this.loading$.next(false);
			})
		);
	}

	addContact(contact: ContactEntity) {
		const { ids } = this.whatsappDcPreviewStore.getValue();
		if (ids?.length === 20) {
			this.whatsappDcPreviewStore.remove(ids[19]);
		}
		this.whatsappDcPreviewStore.add(contact, { prepend: true });
	}

	removeContact(id: number) {
		this.whatsappDcPreviewStore.remove(id);
	}

	removeAllContacts() {
		this.whatsappDcPreviewStore.remove();
	}

	resetStore() {
		this.whatsappDcPreviewStore.reset();
	}
}
