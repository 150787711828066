import { SendingType } from '@enSend/message/message-wizard/message-wizard-common/models';
import { SelectItem } from 'primeng/api';

export const TYPE_OPTIONS: SelectItem<SendingType>[] = [
	{
		label: 'message_types.sendnow',
		value: 'sendnow'
	},
	{
		label: 'message_types.sendlate',
		value: 'sendlate'
	},
	{
		label: 'message_types.regular',
		value: 'regular'
	},
	{
		label: 'message_types.api',
		value: 'api'
	},
	{
		label: 'message_types.event',
		value: 'event'
	},
	{
		label: 'message_types.doi_short',
		value: 'doi'
	}
];
