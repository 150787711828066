import { DATE_FILLER_LENGTH, TuiDay } from '@taiga-ui/cdk';
import IMask from 'imask';

export function createAutoCorrectedDateRangePipe(
	value: string,
	masked: IMask.Masked<string>
) {
	if (value.length < DATE_FILLER_LENGTH) return;

	const date = value.substring(0, 10);
	const dateTo = value.substring(13, 23);

	const dateTui = TuiDay.normalizeParse(date);
	const dateToTui = TuiDay.normalizeParse(dateTo);

	if (dateToTui.year === dateTui.year && dateToTui < dateTui)
		// eslint-disable-next-line no-param-reassign
		masked.value = `${date} - ${date}`;
}
