<en-dialog styleClass="dialog-medium-width"
           [header]="'dc_manager.header' | translate"
           [modal]="true"
           appendTo="body"
           [(visible)]="openDynamicContent"
           (onHide)="onHide.emit()">

    <div class="search">
        <en-search class="input-search"
                   styleClass="h32"
                   [placeholder]="'dc_manager.search' | translate"
                   [refresher]="false"
                   [formControl]="searchControl"></en-search>
    </div>

    <tui-scrollbar>
        <div *tuiLet="searched$ | async as searched"
             class="content">
            <ng-container *ngIf="!searched; else searchedOpt">
                <ng-container *ngFor="let list of DCLists">
                    <h2 class="title">{{ list.title | translate }}</h2>
                    <div *ngFor="let option of list.options"
                         class="option">
                        <button enButton
                                type="button"
                                class="en-button-lowercase"
                                icon="en en-plus-circle-o"
                                iconColor="blue-button-icon"
                                iconPos="left"
                                [label]="option.label | translate"
                                (click)="selectContent(option.value)"></button>
                    </div>
                </ng-container>
            </ng-container>

            <ng-template #searchedOpt>
                <div *ngIf="searchedOptions.length"
                     class="option__searched">
                    <ng-container *ngFor="let option of searchedOptions">
                        <button enButton
                                type="button"
                                class="en-button-lowercase"
                                icon="en en-plus-circle-o"
                                iconColor="blue-button-icon"
                                iconPos="left"
                                [label]="option.label | translate"
                                (click)="selectContent(option.value)"></button>
                    </ng-container>
                </div>

                <div class="empty-search"
                     *ngIf="!searchedOptions.length">{{ 'dc_manager.empty_filter' | translate }}</div>
            </ng-template>

        </div>
    </tui-scrollbar>
</en-dialog>